import { axiosAPI } from '../http'

export function fetchFile(id) {
    return axiosAPI.get(`/villages/__VILLAGE_ID__/files/${id}`);
}

export function fetchFiles() {
    return axiosAPI.get(`/villages/__VILLAGE_ID__/files`);
}

export function deleteFile(id) {
    return axiosAPI.delete(`/villages/__VILLAGE_ID__/files/${id}`);
}

export function uploadFile(data) {
    return axiosAPI.post(`/villages/__VILLAGE_ID__/files`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}