import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'antd'
import dayjs from 'dayjs';
// import { MiniBar, Bar } from 'ant-design-pro/lib/Charts';
import { Rose } from '@ant-design/plots';
// import { fetchSummary } from '../services/invoice'
// import { fetchChart } from '../services/dashboard'
// import { Link } from 'react-router-dom';
import { numFormat } from 'utils/helpers';
import { axiosAPI } from 'http';

const CardStatRose = () => {

    const date = dayjs();
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    // const type = [
    //     'สาธารนูปโภค (น้ำ/ไฟฟ้า)',
    //     'อุปกรณ์ไฟฟ้าชำรุด',
    //     'พื้นที่/อาคารชำรุด',
    //     'อุปกรณ์คอมพิวเตอร์',
    //     'เรื่องความสะอาดทั่วไป',
    //     'เรื่องขยะมูลฝอย/ขยะติดเชื้อ',
    //     'เรื่องห้องน้ำ',
    // ];

    // let count = 0;

    // const data = type.map(v => {
    //     let value = Math.floor(Math.random() * (1000 - 500) + 500);
    //     count += value;
    //     return { type: `${v}`, value: value }
    // });

    // const [chart] = useState(() => {
    //     return [...Array(12)].map((_, i) => {
    //         return { label: dayjs().month(i).format('MMM'), values: Math.floor(Math.random() * 100) };
    //     });
    // });

    // const fetchData = () => {
    //     const params = {
    //         year: date.year(),
    //         month: 0
    //     }
    //     Promise.all([fetchSummary(params), fetchChart(params, isYearly)])
    //         .then(([now, chart]) => {
    //             setNow(now.data)

    //             let chartData;
    //             if (isYearly) {
    //                 if (chart.data?.label?.length > 0) {
    //                     chartData = chart.data.label.slice(3, 8).map((item, idx) => {
    //                         let index = chart.data.label.findIndex(v => v === item);
    //                         return {
    //                             label: (item + 543).toString(),
    //                             values: parseFloat(chart.data.data[index]),
    //                         }
    //                     })
    //                 }
    //             } else {
    //                 chartData = chart.data?.map((item, idx) => {
    //                     return {
    //                         label: dayjs().month(idx).format('MMM'),
    //                         // x: dayjs().month(idx).format('MMMM BBBB'),
    //                         values: parseFloat(item),
    //                     }
    //                 })
    //             }

    //             setChart(chartData)
    //         });
    // }

    useEffect(() => {
        axiosAPI.get("/api/v1/reports?type=number_of_issue_by_service_in_year_range", { params: { year: date.get('year') } }).then(res => {
            let _count = 0;
            const _data = res?.data?.map(v => {
                let value = v?.value ?? 0;
                _count += value;
                return { type: `${v?.name}`, value: value }
            });
            setData(_data);
            setCount(_count);
        });
        // fetchData()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Card style={{ height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col span={12} className="text-grey">จำนวนคำร้องตามประเภทในช่วง 1 ปี</Col>
                    <Col span={12} className="text-right" >
                        {/* <Link to="/common_fee_report" style={{ color: 'black' }}>{date.format('MMMM')}</Link> */}
                        {date.format('BBBB')}
                    </Col>
                    {/* <Col span={12} className="text-right" >{date.format('MMMM')}</Col> */}
                </Row>

                <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                    <Col span={24} style={{ fontSize: '26px' }}>{numFormat(count)}</Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24} >
                        {/* <MiniBar height={45} data={chart} color="#945de2" /> */}
                        {/* <Bar height={100} data={chart} color="#945de2" style={{ maxHeight: '100%' }} /> */}
                        <RoseChart data={data} />
                    </Col>
                </Row>

                {/* <Card.Grid hoverable={false} style={{ width: '100%' }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Space size={8}>
                                <div>ในปี <span className="text-lg">พ.ศ. {date.format('BBBB')}</span></div>
                            </Space>
                        </Col>
                    </Row>
                </Card.Grid> */}
            </Card>
        </>
    )
}

const RoseChart = ({ data }) => {
    const config = {
        data: data,
        xField: 'type',
        yField: 'value',
        seriesField: 'type',
        radius: 0.9,
        label: {
            offset: -20,
        },
    };
    return <Rose {...config} />;
};

export default CardStatRose
