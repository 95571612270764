import { axiosAPI } from '../http'

export function getMember(memberId) {
    return axiosAPI.get(`/villages/__VILLAGE_ID__/chats/${memberId}`);
}

export function listMembers(page = 1) {
    return axiosAPI.get(`/villages/__VILLAGE_ID__/chats`, { params: { page } });
}

export function listSessions(memberId, page = 1) {
    return axiosAPI.get(`/villages/__VILLAGE_ID__/chats/${memberId}/sessions`, { params: { page } });
}

export function listMessages(memberId, sessionId, startedAt, page = 1) {
    const params = {
        page: page,
        started_at: startedAt,
    }
    return axiosAPI.get(`/villages/__VILLAGE_ID__/chats/${memberId}/sessions/${sessionId}/messages`, { params });
}

export function sendMessage(memberId, message) {
    return axiosAPI.post(`/villages/__VILLAGE_ID__/chats/${memberId}`, message);
}

export function closeSession(memberId) {
    return axiosAPI.delete(`/villages/__VILLAGE_ID__/chats/${memberId}`);
}

export function getUncomplete() {
    return axiosAPI.get(`/villages/__VILLAGE_ID__/chats/report/uncomplete`);
}