import React, { useEffect } from "react";
import { Button, Col, Dropdown, Input, Menu, Row, Space, Table } from "antd";
import { DownOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { observer, useLocalObservable } from "mobx-react-lite";
import { axiosAPI } from '../../http';
import { runInAction } from "mobx";
import LabelFilter from "components/LabelFilter";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "utils/helpers";
import ReactJson from "react-json-view";

const LogsTable = ({ onEdit, onDelete, reload }) => {
    const navigate = useNavigate();
    let delayTimer;
    const state = useLocalObservable(() => ({
        loading: false,
        params: { page: 1, take: 15 },
        total: 0,
        per_page: 15,
        dataSource: [],
        fetchData() {
            // this.dataSource = [
            //     { key: 1, name: 'สาธารนูปโภค(น้ำ / ไฟฟ้า)' },
            //     { key: 2, name: 'อุปกรณ์ไฟฟ้าชำรุด' },
            //     { key: 3, name: 'พื้นที่ / อาคารชำรุด' },
            //     { key: 4, name: 'อุปกรณ์คอมพิวเตอร์' },
            // ];
            // // Mock
            // this.per_page = 10;
            // this.total = 3;

            this.setLoading(true)

            const params = this.params

            axiosAPI.get("/api/v1/activities", { params }).then(res => {
                runInAction(() => {
                    this.dataSource = res.data.data.map(data => {
                        return { key: data.id, ...data }
                    });
                    this.per_page = res.data.per_page;
                    this.total = res.data.total;
                    this.setLoading(false)
                })
            });
        },
        changePage(page) {
            this.params.page = page;
            this.fetchData();
        },
        setLoading(loading) {
            this.loading = loading;
        },

        handleSearch(key, value) {
            clearTimeout(delayTimer);

            delayTimer = setTimeout(() => {
                if (!value) {
                    value = undefined;
                }
                this.params = { ...this.params, [key]: value, page: 1 };
                this.fetchData()
            }, 700);
        }
    }));

    useEffect(() => {
        state.fetchData();
        // eslint-disable-next-line
    }, [reload])

    const columns = [
        {
            title: 'กิจกรรม',
            render: (_, row) => {
                if (row?.loggable_type?.toLowerCase()?.includes('issue')) {
                    const comments = row?.comment?.split(' ');
                    const text = comments[0] ?? '';
                    const code = comments[1] ?? '';
                    const id = code?.slice(8) ?? '';

                    if (!id) {
                        return row?.comment
                    }

                    return (
                        <>
                            {text} <Link to={`/complaints/${id}`}>{code}</Link>
                        </>
                    )
                }

                return row?.comment
            }
        },
        {
            title: 'ผู้ดำเนินการ',
            width: '15%',
            render: (_, row) => row?.causer?.name ?? row?.causer?.username ?? '-'
        },
        {
            title: 'วันที่ - เวลา',
            width: '15%',
            render: (_, row) => (
                dayjs(row?.created_at).format(DATE_TIME_FORMAT)
            )
        },
    ];

    return (
        <>
            <Table
                // rowClassName="table-row-pointer"
                loading={state.loading}
                columns={columns}
                dataSource={state.dataSource}
                pagination={{
                    pageSize: state.per_page,
                    current: state.params.page,
                    total: state.total,
                    onChange: (page) => state.changePage(page),
                    showSizeChanger: false
                }}
                scroll={{ x: true }}
                locale={{ emptyText: "ไม่มีข้อมูล" }}
                expandable={{
                    expandedRowRender: (row) => (
                        <ReactJson src={row.data ?? {}} />
                        // <p style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                        //     {JSON?.stringify(row.data, null, '\t')}
                        // </p>
                    ),
                    // expandRowByClick: true
                }}
            />
        </>
    );
};

export default observer(LogsTable);
