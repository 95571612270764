import { Button } from 'antd'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const NotFoundPage = (props) => {

    const navigate = useNavigate();
    const [count, setCount] = React.useState(5);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (count > 0) {
                setCount(count - 1)
            } else {
                clearTimeout(timer)
                navigate('/')
                // props.history.replace('/')
            }
        }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    }, [count, navigate])

    return (
        <div className="text-center">
            <h1 style={{ fontSize: 120 }}>404</h1>
            <h2>Not Found</h2>
            <p>ขออภัยไม่พบหน้าที่คุณค้นหา ดูเหมือนว่าหน้าเว็บที่คุณพยายามเข้าถึงไม่มีอยู่อีกต่อไปหรืออาจจะย้ายไปยังหน้าเว็บเพจอื่น</p>
            <p>จะกลับเข้าสู่หน้าหลักภายใน {count} วินาที</p>
            <Button type="primary">
                <Link to="/" >กลับหน้าหลัก</Link>
            </Button>
        </div>
    )
}

export default NotFoundPage
