import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Card, Col, Row, Divider, Image } from 'antd';
import { EditOutlined, RollbackOutlined } from "@ant-design/icons";

// import * as auth from 'services/auth';
import ProfileModalForm from './ProfileModalForm';
import useBreadcrumb from 'hooks/useBreadcrumb';

import useService from 'hooks/useService';

import { FALLBACK_IMG } from 'utils/helpers'
// import { getMsg } from 'utils/codeMsg';
import { ROLE } from 'utils/wording';

const ProfilePage = () => {

    const { auth: mobxAuth } = useService();
    const profile = mobxAuth.userInfo;
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);

    useBreadcrumb([
        { page: 'โปรไฟล์', link: '/profile', icon: '' }
    ])

    const getProfile = async () => {
        mobxAuth.fetchUserInfo()
    }

    // const handleUpdateProfile = (data) => {
    //     auth.updateProfile(data)
    //         .then(res => {
    //             message.success(getMsg('UPDATE_SUCCESS'))
    //             getProfile();
    //         })
    //         .catch(error => message.error(getMsg('UPDATE_FAIL')))
    // }

    const level = (level) => {
        let role = ROLE[level];
        if (!role) return '-';
        return role;
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col md={{ span: 24 }} lg={{ span: 10 }}>
                    <Card
                        title="โปรไฟล์"
                    >
                        <Row gutter={16} style={{ marginBottom: '10px' }}>
                            <Col span={4}>
                                <b>ชื่อ - สกุล</b>
                            </Col>
                            <Col span={20}>
                                {profile?.name}
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ marginBottom: '10px' }}>
                            <Col span={4}>
                                <b>อีเมล</b>
                            </Col>
                            <Col span={20}>
                                {profile?.email}
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ marginBottom: '10px' }}>
                            <Col span={4}>
                                <b>ระดับ</b>
                            </Col>
                            <Col span={20}>
                                {profile?.role ?? '-'}
                            </Col>
                        </Row>

                        {/* {profile?.level !== 9 && (
                            <Row gutter={16} style={{ marginBottom: '10px' }}>
                                <Col span={4}>
                                    <b>ลายเซ็น</b>
                                </Col>
                                <Col span={20}>
                                    <Image
                                        width={150}
                                        src={profile?.signature ?? null}
                                        fallback={FALLBACK_IMG}
                                    />
                                </Col>
                            </Row>
                        )} */}

                        <Divider />

                        <Row gutter={16}>
                            <Col span={12} align="left">
                                <Button onClick={() => navigate(-1)} type="info" icon={<RollbackOutlined />}>ย้อนกลับ</Button>
                            </Col>
                            {profile?.role === 'admin' && (
                                <Col span={12} align="right">
                                    <Button onClick={() => setVisible(true)} type="warning" icon={<EditOutlined />}>แก้ไข</Button>
                                </Col>
                            )}
                        </Row>
                    </Card>
                </Col>

                <Col span={14}>
                </Col>
            </Row>

            {visible ?
                <ProfileModalForm
                    visible={visible}
                    onCancel={() => {
                        setVisible(false)
                    }}
                    reload={() => getProfile()}
                    data={profile}
                />
                :
                null
            }
        </>
    )
}

export default ProfilePage
