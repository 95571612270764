import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { axiosAPI, setup as interceptors } from './http';

import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import 'dayjs/locale/th'

var buddhistEra = require('dayjs/plugin/buddhistEra')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(buddhistEra)
dayjs.extend(timezone)
dayjs.locale('th')
dayjs.tz.setDefault("Asia/Bangkok")

interceptors(axiosAPI)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
