import * as authAPI from './auth';
import { makeObservable, observable, action } from "mobx";
// import { getLogo } from "./app";

export default class AppService {
    app = null;
    logo = null;
    layout = 'layout-space';
    sidebarCollapsed = false;
    breadcrumbs = [];
    subMenus = ['/reports'];
    reload = null;

    constructor() {
        makeObservable(this, {
            app: observable,
            logo: observable,
            layout: observable,
            sidebarCollapsed: observable,
            breadcrumbs: observable,
            subMenus: observable,
            reload: observable,

            setLogo: action,
            setOpenSubMenus: action,
            setLayout: action,
            setSidebarCollapsed: action,
            setBreadcrumbs: action,
            setReload: action
        });
    }

    getToken() {
        return authAPI.getToken()?.access_token ?? null;
    }

    // async fetchLogo() {
    //     const response = await getLogo();
    //     runInAction(() => {
    //         this.logo = response.data.logo
    //     })
    // }

    setLogo(logo) {
        if (!logo) return null
        this.logo = logo
    }

    setOpenSubMenus(subMenus) {
        if (!subMenus) return null
        this.subMenus = subMenus
    }

    setLayout(layout) {
        if (!layout) {
            this.layout = ''
        }

        this.layout = layout
    }

    setBreadcrumbs(breadcrumbs) {
        this.breadcrumbs = breadcrumbs
    }

    setReload() {
        this.reload = Date.now()
    }

    setSidebarCollapsed(value) {
        this.sidebarCollapsed = value;
    }
}