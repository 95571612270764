import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./DefaultLayout.css";
import { Col, Layout, message, Row } from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";

import SidebarLayout from "./SidebarLayout";
import Profile from '../components/Profile'
import BreadcrumbLayout from "./BreadcrumbLayout";
import ModalLog from "../components/ModalLog";
// import BackgroundNotify from "./BackgroundNotify";

import useService from "../hooks/useService";
import ModalLayout from "./ModalLayout";
// import ModalSettingCheck from "./ModalSettingCheck";

const { Header, Sider, Content, Footer } = Layout;

function DefaultLayout(props) {
    const { app } = useService()

    const [version, setVersion] = useState(false);

    const toggle = () => {
        app.setSidebarCollapsed(!app.sidebarCollapsed);
    };

    message.config({
        duration: 3.5,
    });

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                theme="light"
                trigger={null}
                collapsible
                collapsed={app.sidebarCollapsed}
                onCollapse={(collapsed) => app.setSidebarCollapsed(collapsed)}
                style={{ boxShadow: 'rgb(32 5 66 / 10%) 0px 0px 50px' }}
                breakpoint='lg'
                collapsedWidth='80'
            >
                <SidebarLayout />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    <Row justify="space-between">
                        <Col>
                            {React.createElement(
                                app.sidebarCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                {
                                    className: "trigger",
                                    onClick: toggle,
                                }
                            )}
                        </Col>
                        <Col style={{ marginRight: 20 }}>
                            <Profile />
                        </Col>
                    </Row>
                </Header>
                <Content
                    className={app.layout}
                >
                    {/* Breadcrumb */}
                    <BreadcrumbLayout />

                    <div>
                        <Outlet />
                        {/* {props.children} */}
                    </div>

                    <ModalLayout />
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Smart Police Service (DEMO) ©{new Date().getFullYear()} Created by CODESMASH
                    <p>Version <span onClick={() => setVersion(true)} className="text-lg" style={{ cursor: 'pointer' }}>{process.env.REACT_APP_VERSION}</span></p>

                    {version && (
                        <ModalLog
                            visible={true}
                            onCancel={() => setVersion(false)}
                        />
                    )}
                </Footer>
                {/* <ModalSettingCheck />
                <BackgroundNotify /> */}
            </Layout>
        </Layout>
    );
}

export default observer(DefaultLayout)