import React, { Component } from 'react';
import { Table, Card, Menu, Row, Col, Input, Select } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";
import DatePicker, { locale } from 'components/DatePicker'
import UserName from 'components/UserName';
import LabelFilter from 'components/LabelFilter';
import UserLogModalDetail from './UserLogModalDetail'

// import { getNameFromLogCodeName, logNameMapString } from 'utils/log'
import { fetchUserLogs, fetchUsers } from 'services/users';
import dayjs from 'dayjs';
import useBreadcrumb from 'hooks/useBreadcrumb';
import { DATE_TIME_FORMAT } from 'utils/helpers';

class PrivateUserLogsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            datas: null,
            data: null,
            loading: false,
            params: {
                order: 'id',
                desc: true,
                page: 1,
                search: null,
                type: null,
                created_by: null,
                start: dayjs().startOf('day').subtract(7, 'day').format(),
                end: dayjs().endOf('day').format(),
            },
            users: null
        }
        this.delayTimer = null;
    }

    componentDidMount() {
        this.handleFetchDatas();
        this.handleFetcUsers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.params !== this.state.params) {
            this.handleFetchDatas();
        }

        if (prevState.params.start !== this.state.params.start) {
            this.checkDateRange('start')
        }

        if (prevState.params.end !== this.state.params.end) {
            this.checkDateRange('end')
        }
    }

    handleFetchDatas = () => {
        this.setState({ loading: true })
        fetchUserLogs(this.state.params)
            .then(res => this.setState({ datas: res.data }))
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    handleFetcUsers = () => {
        fetchUsers({ column: 'fullname' })
            .then(res => this.setState({ users: res.data.data }))
    }

    handleViewData = (data) => {
        this.setState({
            visible: true,
            data: data
        })
    }

    handleChangPage = (page) => {
        this.setState({
            params: {
                ...this.state.params,
                page: page
            }
        })
    }

    handleSearch = (key, search) => {
        clearTimeout(this.delayTimer);

        this.delayTimer = setTimeout(() => {
            if (!search) {
                search = undefined;
            }
            this.setState({
                params: {
                    ...this.state.params,
                    [key]: search,
                    page: 1
                }
            })
        }, 500);
    }

    checkDateRange = (type) => {
        let startDate = this.state.params.start;
        let endDate = this.state.params.end;
        if (type === 'start') {
            if (startDate > endDate) {
                this.handleSearch('end', startDate)
            }
        } else {
            if (startDate > endDate) {
                this.handleSearch('start', endDate)
            }
        }

    }

    handleDropdownMenuClick = (e) => {
        switch (e.key) {
            case 'view':
                break;
            case 'edit':
                break;
            case 'delete':
                break;
            default:
                break;
        }
    }

    dropdown_menu = (row) => (
        <Menu onClick={this.handleDropdownMenuClick}>
            {/* <Menu.Item key="view" icon={<SearchOutlined />} value={row}>
                ดูข้อมูล
            </Menu.Item> */}
            <Menu.Item key="edit" icon={<EditOutlined />} value={row}>
                แก้ไข
            </Menu.Item>
            <Menu.Item key="delete" icon={<DeleteOutlined />} value={row}>
                ลบ
            </Menu.Item>
        </Menu>
    );

    render() {
        const dateFormat = 'DD/MM/BBBB';

        // Table
        const columns = [
            {
                title: 'กิจกรรม',
                dataIndex: 'name',
                key: 'name',
                width: '30%',
                render: (name, row) => {
                    // return (
                    //     getNameFromLogCodeName(name, row.value)
                    // )
                }
            },
            {
                title: 'ผู้ดำเนินการ',
                dataIndex: 'created_by',
                key: 'created_by',
                render: (created_by) => <UserName id={created_by} />
            },
            {
                title: 'วันที่ - เวลา',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (created_at) => (
                    dayjs(created_at).format(DATE_TIME_FORMAT)
                )
            },
        ];

        const datas = [];

        this.state.datas &&
            this.state.datas.data.forEach((data) => {
                datas.push({
                    key: data.id,
                    id: data.id,
                    name: data.name,
                    value: data.value,
                    created_at: data.created_at,
                    created_by: data.created_by,
                });
            });

        // const type = [];
        // for (const item in logNameMapString) {
        //     if (typeof logNameMapString[item] === 'string') {
        //         type.push(item);
        //     }
        // }

        return (
            < >
                <Card
                    title="ประวัติการใช้งาน"
                >
                    <Row gutter={[16, 16]} style={{ marginBottom: '10px' }} align="bottom">
                        <Col span={4}>
                            <LabelFilter label="ประเภทกิจกรรม" >
                                <Select
                                    defaultValue=""
                                    style={{ width: '100%' }}
                                    onChange={(value) => this.handleSearch('type', value)}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="">ประเภททั้งหมด</Select.Option>
                                    {/* {type.map((item, index) => (
                                        <Select.Option key={index} value={item}>{logNameMapString[item]}</Select.Option>
                                    ))} */}
                                </Select>
                            </LabelFilter>
                        </Col>
                        <Col span={4}>
                            <LabelFilter label="ผู้ดำเนินการ" >
                                <Select
                                    defaultValue=""
                                    style={{ width: '100%' }}
                                    onChange={(value) => this.handleSearch('created_by', value)}>
                                    <Select.Option value="">ผู้ดำเนินการทั้งหมด</Select.Option>
                                    {this.state?.users?.map((item, idx) => (
                                        <Select.Option key={idx} value={item.id}>{item.fullname}</Select.Option>
                                    ))}
                                </Select>
                            </LabelFilter>
                        </Col>
                        <Col span={4}>
                            <LabelFilter label="ตั้งแต่วันที่" >
                                <DatePicker
                                    allowClear={false}
                                    placeholder="ตั้งแต่วันที่"
                                    style={{ width: '100%' }}
                                    format={dateFormat}
                                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                                    onChange={(date) => this.handleSearch('start', dayjs(date).startOf('day').format())}
                                    value={dayjs(this.state.params.start)}
                                    locale={locale}
                                />
                            </LabelFilter>

                        </Col>
                        <Col span={4}>
                            <LabelFilter label="ถึงวันที่" >
                                <DatePicker
                                    allowClear={false}
                                    placeholder="ถึงวันที่"
                                    style={{ width: '100%' }}
                                    format={dateFormat}
                                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                                    onChange={(date) => this.handleSearch('end', dayjs(date).endOf('day').format())}
                                    value={dayjs(this.state.params.end)}
                                    locale={locale}
                                />
                            </LabelFilter>
                        </Col>
                        <Col span={4}>
                            <LabelFilter label="ค้นหา" >
                                <Input
                                    placeholder="ค้นหา"
                                    allowClear
                                    onChange={(e) => this.handleSearch('search', e.target.value)}
                                    autoComplete="off"
                                />
                            </LabelFilter>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Table
                                rowClassName="table-row-pointer"
                                loading={this.state.loading}
                                columns={columns}
                                dataSource={datas}
                                pagination={{
                                    pageSize: 10,
                                    current: this.state.params.page,
                                    total: this.state.datas?.total,
                                    onChange: (page) => this.handleChangPage(page),
                                    showSizeChanger: false
                                }}
                                scroll={{ x: true }}
                                locale={{ emptyText: 'ไม่มีข้อมูล' }}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { this.handleViewData(record) }, // click row
                                    };
                                }}
                            />
                        </Col>
                    </Row>
                </Card>

                {this.state.visible ?
                    <UserLogModalDetail
                        visible={this.state.visible}
                        onCancel={() => this.setState({
                            visible: false,
                            data: null
                        })}
                        data={this.state.data}
                    />
                    :
                    null
                }
            </>
        )
    }
}

const UserLogsPage = () => {
    useBreadcrumb([
        { page: 'ประวัติการใช้งาน', link: '/user_logs', icon: '' }
    ])

    return <PrivateUserLogsPage />
}

export default UserLogsPage;