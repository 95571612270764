import React, { useCallback, useEffect } from 'react'
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import message from 'antd/lib/message';
import Input from 'antd/lib/input';

// import { NORMAL_REGEX } from 'utils/helpers';
import useService from 'hooks/useService';
import { getMsg } from 'utils/codeMsg';

export function useCreateModal(onSubmit) {
    const { modal } = useService();
    const [form] = Form.useForm();

    return useCallback((data) => {
        modal.setModal({
            show: true,
            header: `เพิ่มตำแหน่งหน้าที่`,
            content: <BaseForm form={form} data={data} />,
            size: 500,
            okText: "บันทึก",
            cancelText: "ยกเลิก",

            onOk: () => {
                form
                    .validateFields()
                    .then((values) => {
                        onSubmit(values)
                            .then(res => {
                                form.resetFields()
                                message.success(getMsg('CREATE_SUCCESS'))
                                modal.closeModal()
                            })
                            .catch(err => message.error(getMsg('CREATE_FAIL')))
                    })
                    .catch((info) => {
                    });
            },
            onCancel: () => {
                form.resetFields();
                modal.closeModal();
            },
        });
    }, [modal, onSubmit, form]);
}

export function useEditModal(onSubmit) {
    const { modal } = useService();
    const [form] = Form.useForm();

    return useCallback((data) => {
        modal.setModal({
            show: true,
            header: `แก้ไขตำแหน่งหน้าที่`,
            content: <BaseForm form={form} data={data} />,
            size: 500,
            okText: "บันทึก",
            cancelText: "ยกเลิก",

            onOk: () => {
                form
                    .validateFields()
                    .then((values) => {
                        values.id = data?.id;

                        onSubmit(values)
                            .then(res => {
                                form.resetFields()
                                message.success(getMsg('UPDATE_SUCCESS'))
                                modal.closeModal()
                            })
                            .catch(err => message.error(getMsg('UPDATE_FAIL')))
                    })
                    .catch((info) => {
                    });
            },
            onCancel: () => {
                form.resetFields();
                modal.closeModal();
            },
        });
    }, [modal, onSubmit, form]);
}

export default function BaseForm({ data, setCancel, form }) {

    useEffect(() => {
        form.setFieldsValue({
            name: data?.name,
        })
        // eslint-disable-next-line
    }, [])

    return (
        <Form
            form={form}
            layout="vertical"
            name="control-hooks"
        // onValuesChange={() => setCancel(true)}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="ตำแหน่งหน้าที่"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกตำแหน่งหน้าที่'
                            },
                        ]}
                        initialValue={data?.name}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                </Col>
            </Row>
        </Form >
    )
}