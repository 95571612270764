import { runInAction, makeObservable, observable } from "mobx";
import * as authAPI from './auth';
// import { fetchVillage, fetchVillageSetting } from './villages';
// import { getPackageById } from './package';
// import { getPresets } from './presets';

export default class AuthService {
    userInfo = null;
    villageInfo = null;
    settingInfo = null;
    packageInfo = null;
    isYearly = false; // เพื่อเช็คว่าคิดค่าส่วนกลางเป็นรายเดือน หรือรายปี

    ready = false;
    isLoggedIn = false;

    constructor() {
        makeObservable(this, {
            ready: observable,
            userInfo: observable,
            isLoggedIn: observable,

            villageInfo: observable,
            settingInfo: observable,
            packageInfo: observable,
            isYearly: observable
        });
    }

    getToken() {
        return authAPI.getToken()?.access_token ?? null;
    }

    get village_id() {
        return this.userInfo?.village_id ?? null;
    }

    async fetchStarterInfo(cb = null) {
        if (!this.getToken()) {
            return runInAction(() => {
                this.ready = true;
                this.isLoggedIn = false;
            });
        }

        try {
            const response = await authAPI.authSession();
            runInAction(() => {
                this.isLoggedIn = true;
                this.userInfo = response.data;
            });
        } catch (error) {
            if (error?.response?.data?.code === 'NO_AUTH') {
                authAPI.authLogout();
            }
        }

        // if (this.userInfo?.level !== 9) {
        //     await this.fetchVillageInfo()
        //     await this.fetchVillageSettingInfo()
        //     await this.fetchPackage()
        // }

        runInAction(() => {
            this.ready = true;
        });

        cb?.(this.userInfo);
    }

    async fetchUserInfo() {
        const response = await authAPI.authSession();
        runInAction(() => {
            this.userInfo = response.data;
        });
    }

    // async fetchVillageInfo() {
    //     if (!this?.userInfo) return null

    //     try {
    //         const response = await fetchVillage(this.userInfo.village_id);
    //         runInAction(() => {
    //             this.villageInfo = response.data;
    //             this.isYearly = response.data?.is_yearly ?? false;
    //         });
    //     } catch (error) {
    //     }
    // }

    // setVillageInfo(villageInfo) {
    //     if (!villageInfo) return null

    //     runInAction(() => {
    //         this.villageInfo = villageInfo;
    //     });
    // }

    // async fetchVillageSettingInfo() {
    //     if (!this?.userInfo) return null

    //     try {
    //         const response = await getPresets(this.userInfo.village_id);
    //         runInAction(() => {
    //             this.settingInfo = { ...this.settingInfo, ...response.data };
    //         });
    //     } catch (error) {
    //     }

    //     try {
    //         const responseSetting = await fetchVillageSetting(this.userInfo.village_id);
    //         runInAction(() => {
    //             this.settingInfo = { ...this.settingInfo, ...responseSetting.data };
    //         });
    //     } catch (error) {

    //     }
    // }

    // async fetchPackage() {
    //     if (!this?.userInfo) return null

    //     try {
    //         const response = await getPackageById(this.userInfo.village_id)
    //         runInAction(() => {
    //             this.packageInfo = response.data
    //         })
    //     } catch (error) {
    //     }
    // }

    // async fetchSetting() {
    //     try {
    //         const responseSetting = await fetchVillageSetting(this.userInfo.village_id);
    //         runInAction(() => {
    //             this.settingInfo = { ...this.settingInfo, ...responseSetting.data };
    //         });
    //     } catch (error) {

    //     }
    // }
}