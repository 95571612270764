import React, { cloneElement } from 'react'
import Modal from 'antd/lib/modal/Modal';
import useService from '../hooks/useService';
import { observer } from 'mobx-react-lite';

const ModalLayout = () => {

    const { modal } = useService();

    const handleClose = () => {
        modal.closeModal();
    };

    return (
        <Modal
            style={{ top: '20px' }}
            width={modal.initModal.size}
            visible={modal.initModal.show}
            title={modal.initModal.header}
            okText={modal.initModal.okText}
            cancelText={modal.initModal.cancelText}
            onOk={modal.initModal.onOk}
            onCancel={modal.initModal.onCancel}
            // footer={null}
            okButtonProps={{
                style: {
                    display: modal.initModal?.okButtonProps
                }
            }}
            cancelButtonProps={modal.initModal?.cancelButtonProps}
        >
            {cloneElement(modal.initModal.content, { onClose: handleClose })}
        </Modal >
    );
}

export default observer(ModalLayout)