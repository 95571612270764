import React from 'react'

const LabelFilter = ({ children, label = '' }) => {
    return (
        <>
            <div className="text-lg">{label}</div>
            {children}
        </>
    )
}

export default LabelFilter
