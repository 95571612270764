import axios from 'axios';
import { getToken, authLogout } from './services/auth'
// import { services } from './contexts/service';

export const axiosAPI = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
});

export function setup(axiosAPI) {
    axiosAPI.interceptors.request.use(
        (config) => {
            // if (config.url.includes('__VILLAGE_ID__')) {
            //     config.url = config.url.replace('__VILLAGE_ID__', services.auth.village_id);
            // }

            const TOKEN = getToken()?.access_token;
            if (TOKEN) {
                config.headers['Authorization'] = `Bearer ${TOKEN}`;
            }
            return config;
        },
        (error) => Promise.reject(error),
    );

    axiosAPI.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response && error?.response?.data?.code === 'NO_AUTH') {
                authLogout()
                window.location.reload()
            }
            return Promise.reject(error);
        },
    );
}