import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import * as auth from '../services/auth';
import { Form, Input, Button, Checkbox, Row, Col, Card, message, Image } from 'antd';
import { getMsg } from '../utils/codeMsg'

export default function LoginPage() {
    let navigate = useNavigate();
    const token = auth.getToken();

    // useEffect(() => {
    //     if (token) {
    //         navigate('/');
    //     }
    // }, [token, navigate])

    const onFinish = async (values) => {
        try {
            values.remember_me = false;
            if (values.remember) {
                values.remember_me = values.remember;
            }
            delete values.remember;

            const response = await auth.authLogin(values);
            auth.setToken(response.data)

            window.location.reload()
        } catch (error) {
            message.error(getMsg(error?.response?.data?.code))
        }
    };

    const onFinishFailed = (errorInfo) => {
        message.error(getMsg('UNKNOW'))
    };

    return (
        <div className="login-container">
            {/* <div className="login-bg" style={{ backgroundImage: `url("/images/login-bg.jpg")` }}></div> */}
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col>
                    <Card bodyStyle={{ width: '400px', boxShadow: '0 0 100px rgb(0 0 0 / 8%)' }}>
                        <Row justify="center" style={{ marginBottom: '10px' }}>
                            <Col>
                                <div className="login-logo">
                                    <Image
                                        preview={false}
                                        src="/images/logo_short.png"
                                        style={{
                                            height: '150px',
                                            width: 'auto',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Form
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    layout='vertical'
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Form.Item
                                        label="อีเมล หรือ ชื่อผู้ใช้"
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกชื่อเข้าใช้งาน',
                                            },
                                        ]}
                                        initialValue=""
                                    >
                                        <Input size="large" />
                                    </Form.Item>

                                    <Form.Item
                                        label="รหัสผ่าน"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกรหัสผ่าน',
                                            },
                                        ]}
                                        initialValue=""
                                    >
                                        <Input.Password size="large" />
                                    </Form.Item>

                                    {/* <Form.Item name="remember" valuePropName="checked">
                                        <Checkbox>จำฉันไว้</Checkbox>
                                    </Form.Item> */}

                                    <Form.Item >
                                        <Button size="large" className="login-button" block type="primary" htmlType="submit">
                                            ลงชื่อเข้าใช้
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
