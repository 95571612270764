import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
// import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { EnvironmentOutlined } from '@ant-design/icons';

import { axiosAPI } from '../../http';
import { Table } from 'antd';
import { DATE_PARAM_FORMAT, DATE_TIME_SHORT_FORMAT, truncate } from 'utils/helpers';
// import { statusTag } from './utils';
// import LabelFilter from 'components/LabelFilter';
// import { locale } from 'components/DatePicker';
import useHover from 'hooks/useHover';
import LocationLink from './detail.link';

// const _ComplaintsTableP = (props) => {
//     const navigate = useNavigate();
//     let delayTimer;

//     const state = useLocalObservable(() => ({
//         loading: false,
//         params: {
//             page: 1,
//             take: 10,
//             // eslint-disable-next-line
//             // ['filter[date]']: '2022-01-01~2022-12-30',
//             // eslint-disable-next-line
//             ['filter[status]']: props?.status === 'all' ? undefined : props?.status,
//             start_date: dayjs().startOf('month').format(DATE_PARAM_FORMAT),
//             ended_date: dayjs().endOf('day').format(DATE_PARAM_FORMAT)
//         },
//         total: 0,
//         per_page: 15,
//         dataSource: [],
//         typeList: [],
//         fetchData() {
//             this.dataSource = [];
//             this.setLoading(true)

//             const params = {
//                 ...this.params,
//                 // eslint-disable-next-line
//                 ['filter[date]']: `${this.params.start_date}~${this.params.ended_date}`
//             }
//             delete params.start_date
//             delete params.ended_date

//             axiosAPI.get("/api/v1/issues?source=LINE&order[id]=desc&includes[issue_type]=1", { params }).then(res => {
//                 runInAction(() => {
//                     this.dataSource = res.data.data.map(data => {
//                         return { key: data.id, ...data }
//                     });
//                     this.per_page = res.data.per_page;
//                     this.total = res.data.total;
//                     this.setLoading(false)
//                 })
//             });
//         },
//         fetchTypeList() {
//             this.typeList = [];
//             axiosAPI.get("/api/v1/issue-types?page=1&take=9999").then(res => {
//                 runInAction(() => {
//                     this.typeList = res.data.data.map(data => {
//                         return { key: data.id, ...data }
//                     });
//                 })
//             });
//         },
//         changePage(page) {
//             this.params.page = page;
//             this.fetchData();
//         },
//         setLoading(loading) {
//             this.loading = loading;
//         },

//         handleSearch(key, value) {
//             clearTimeout(delayTimer);

//             delayTimer = setTimeout(() => {
//                 if (!value) {
//                     value = undefined;
//                 }
//                 this.params = { ...this.params, [key]: value, page: 1 };
//                 this.fetchData()
//             }, 700);
//         },
//         handleChangeParams(key, value) {
//             if (!value) {
//                 value = undefined;
//             }
//             this.params = { ...this.params, [key]: value };
//         },
//         checkDateRange(type) {
//             let startDate = this.params.start_date;
//             let endDate = this.params.ended_date;

//             if (type === 'start_date') {
//                 if (startDate > endDate) {
//                     this.handleChangeParams('ended_date', dayjs(startDate).endOf('day').format(DATE_PARAM_FORMAT))
//                 }
//             } else {
//                 if (startDate > endDate) {
//                     this.handleChangeParams('start_date', dayjs(endDate).startOf('day').format(DATE_PARAM_FORMAT))
//                 }
//             }
//             this.fetchData();
//         },
//     }));

//     useEffect(() => {
//         if (!props?.reload) return
//         state.fetchTypeList();
//         state.fetchData();
//         // eslint-disable-next-line
//     }, [props?.reload])

//     const COLUMNS = [
//         {
//             title: 'วันที่แจ้ง',
//             fixed: 'left',
//             width: '12%',
//             render: (row) => (
//                 `${dayjs(row.created_at).format(DATE_TIME_SHORT_FORMAT)}`
//             )
//         },
//         {
//             title: 'ผู้ร้องเรียน',
//             width: '12%',
//             render: (row) => row?.name ? row?.name : 'ไม่ระบุ'
//         },
//         {
//             title: 'รายละเอียด',
//             width: '100',
//             render: (row) => (
//                 <span style={{ whiteSpace: 'pre' }}>{truncate(row?.description, 110)}</span>
//             )
//         },
//         {
//             title: 'ประเภท',
//             width: '12%',
//             render: (row) => row?.issue_type_friendly_name ?? '-'
//         },
//     ];

//     if (props?.status === 'all') {
//         COLUMNS.push({
//             title: 'สถานะ',
//             width: '10%',
//             align: 'center',
//             render: (row) => (
//                 <Tag style={{ width: '100%', textAlign: 'center' }} color={statusTag[row.status].color}>{row?.status_friendly_name}</Tag>
//             )
//         });
//     }

//     return (
//         <>
//             <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
//                 <Col span={4}>
//                     <LabelFilter label="ตั้งแต่" >
//                         <DatePicker
//                             style={{ width: '100%' }}
//                             locale={locale}
//                             allowClear={false}
//                             placeholder="ตั้งแต่"
//                             format={'DD/MM/BBBB'}
//                             disabledDate={(current) => current && current > dayjs().endOf('day')}
//                             onChange={(date) => {
//                                 state.handleChangeParams('start_date', dayjs(date).startOf('day').format(DATE_PARAM_FORMAT))
//                                 state.checkDateRange('start_date')
//                             }}
//                             value={dayjs(state.params.start_date)}
//                         />
//                     </LabelFilter>
//                 </Col>
//                 <Col span={4}>
//                     <LabelFilter label="ถึง" >
//                         <DatePicker
//                             style={{ width: '100%' }}
//                             locale={locale}
//                             allowClear={false}
//                             placeholder="ถึง"
//                             format={'DD/MM/BBBB'}
//                             disabledDate={(current) => current && current > dayjs().endOf('day')}
//                             onChange={(date) => {
//                                 state.handleChangeParams('ended_date', dayjs(date).endOf('day').format(DATE_PARAM_FORMAT))
//                                 state.checkDateRange('ended_date')
//                             }}
//                             value={dayjs(state.params.ended_date)}
//                         />
//                     </LabelFilter>
//                 </Col>
//                 <Col span={4}>
//                     <LabelFilter label="ประเภท">
//                         <Select
//                             defaultValue=""
//                             showSearch
//                             filterOption={(input, option) =>
//                                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//                             }
//                             placeholder="เลือกประเภท"
//                             style={{ width: '100%' }}
//                             onChange={(value) => state.handleSearch('filter[issue_type_ids]', value)}
//                         >
//                             <Select.Option value="">ทั้งหมด</Select.Option>
//                             {state.typeList.map(v => (
//                                 <Select.Option key={v?.id} value={v?.id}>{v?.name}</Select.Option>
//                             ))}
//                         </Select>
//                     </LabelFilter>
//                 </Col>
//                 <Col span={4}>
//                     <LabelFilter label="ค้นหา">
//                         <Input
//                             placeholder="ค้นหา"
//                             allowClear
//                             onChange={(e) => state.handleSearch('search', e.target.value)}
//                             autoComplete="off"
//                         />
//                     </LabelFilter>
//                 </Col>
//             </Row>

//             <Table
//                 rowClassName="table-row-pointer"
//                 loading={state.loading}
//                 columns={COLUMNS}
//                 dataSource={state.dataSource}
//                 pagination={{
//                     pageSize: state.per_page,
//                     current: state.params.page,
//                     total: state.total,
//                     onChange: (page) => state.changePage(page),
//                     showSizeChanger: false
//                 }}
//                 scroll={{ x: true }}
//                 locale={{ emptyText: 'ไม่มีข้อมูล' }}
//                 onRow={(record, rowIndex) => {
//                     return {
//                         onClick: event => navigate(`/complaints/${record?.id}`), // click row
//                     };
//                 }}
//             />
//         </>
//     )
// }

const ComplaintsTable = function (props) {
    const state = useRef({});
    const navigate = useNavigate();
    const [hover, hoverAttrs] = useHover();

    const [list, setList] = useState();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState(function () {
        return props.status ? { status: [props.status] } : {};
    });

    const fetcher = useCallback(function (take = 10, page = 1, search = '', filter = {}) {
        setLoading(() => true);

        const params = {
            take,
            page,
            filter,
        };

        if (search = search.trim()) {
            params.search = search;
        }

        const paramsSerializer = function (params) {
            return qs.stringify(params)
        }

        axiosAPI.get('/api/v1/issues', { params, paramsSerializer })
            .then((resp) => {
                setList(() => resp.data.data);
                setTotal(() => resp.data.total);
            }).finally(() => {
                setLoading(() => false);
            });
    }, []);


    const columns = useMemo(function () {
        return [
            {
                title: 'เลขที่คำร้อง',
                fixed: 'left',
                width: '1%',
                render: (row) => row?.code
            },
            {
                title: 'วันที่แจ้ง',
                fixed: 'left',
                width: '10%',
                render: (row) => dayjs(row.created_at).format(DATE_TIME_SHORT_FORMAT)
            },
            {
                title: 'ผู้แจ้ง',
                width: '15%',
                render: (row) => row?.created_user_name ?? 'ไม่ระบุ'
            },
            {
                title: 'รายละเอียด',
                width: '100',
                render: (row) => (
                    <span style={{ whiteSpace: 'pre' }}>{truncate(row?.description, 110)}</span>
                )
            },
            {
                title: 'ประเภท',
                width: '15%',
                render: (row) => row?.service_name ?? '-'
            },
            {
                title: 'ตำแหน่ง',
                width: '20%',
                onCell: () => ({
                    ...hoverAttrs
                }),
                render: (row) => (
                    <LocationLink data={row?.location_ext} />
                    // <a
                    //     href={`https://www.google.com/maps/place/${row?.location_ext?.coordinate}`}
                    //     style={{ whiteSpace: 'pre-wrap', pointerEvents: 'auto' }}
                    //     target='_blank'
                    // >
                    //     <EnvironmentOutlined /> {row?.location_ext?.address ?? '-'}
                    // </a>
                )
                // render: (row) => (
                //     <Breadcrumb>
                //         <Breadcrumb.Item>{row?.building_name ?? '-'}</Breadcrumb.Item>
                //         <Breadcrumb.Item>{row?.floor_name ?? '-'}</Breadcrumb.Item>
                //         <Breadcrumb.Item>{row?.department_name ?? '-'}</Breadcrumb.Item>
                //     </Breadcrumb>
                // )
            },
        ];
    }, []);

    useEffect(function () {
        fetcher(take, page, search, filter);
        state.current.refresh = () => fetcher(take, page, search, filter);
    }, [fetcher, take, page, search, filter]);

    useEffect(function () {
        props?.onLoaded?.(props.index, total);
    }, [total]);

    return (
        <Table
            rowKey={'id'}
            rowClassName="table-row-pointer"
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
                pageSize: take,
                current: page,
                total: total,
                onChange: (page) => setPage(page),
                showSizeChanger: false
            }}
            scroll={{ x: true }}
            locale={{ emptyText: 'ไม่มีข้อมูล' }}
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => !hover && navigate(`/complaints/${record?.id}`), // click row
                };
            }}
        />
    );
}

export default ComplaintsTable;