import React from 'react'
import { Card, Col, Row } from 'antd'
import { observer, useLocalObservable } from 'mobx-react-lite';

import useBreadcrumb from 'hooks/useBreadcrumb'
import LogsTable from './logs.table';

const LogsPage = () => {

    useBreadcrumb([
        { page: 'ประวัติการใช้งาน', link: '/user_logs', icon: '' }
    ])

    const state = useLocalObservable(() => ({
        reload: null,
        setReload() {
            this.reload = Date.now()
        }
    }));

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col md={{ span: 24 }} lg={{ span: 24 }}>
                    <Card
                        title="ประวัติการใช้งาน"
                    >
                        <LogsTable reload={state.reload} />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default observer(LogsPage)