import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Modal } from 'antd';
import { UserOutlined, LogoutOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as auth from '../services/auth';

import { observer } from 'mobx-react-lite';
import useService from '../hooks/useService';

const Profile = () => {
    const navigate = useNavigate();
    const { auth: { userInfo: profile } } = useService();

    const handleMenuClick = (e) => {
        if (e.key === "logout") {
            Modal.confirm({
                title: 'ต้องการออกจากจากระบบใช่หรือไม่ ?',
                icon: <ExclamationCircleOutlined />,
                content: '',
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
                onOk: handleLogout
            });
        } else {
            navigate(`/${e.key}`);
        }
    }

    const handleLogout = async () => {
        auth.authLogout();
        navigate('/', { replace: true });
        window.location.reload()
        // try {
        //     const resp = await auth.authSignout();
        //     if (resp.status === 200) {
        //         auth.authLogout();
        //         window.location.reload()
        //     }
        // } catch (error) {
        //     console.log(error.response.data.error)
        // }
    }

    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={[
                { label: 'โปรไฟล์', key: 'profile', icon: <UserOutlined /> },
                { type: 'divider' },
                { label: 'ออกจากระบบ', key: 'logout', icon: <LogoutOutlined /> },
            ]}
        >
            {/* <Menu.Item key="profile" icon={<UserOutlined />}>
                โปรไฟล์
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
                ออกจากระบบ
            </Menu.Item> */}
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                <a style={{ color: 'black' }} className="ant-dropdown-link" href="/" onClick={e => e.preventDefault()}>
                    {/* <span className="text-grey">สวัสดี, </span> {profile?.fullname} <Avatar style={{ marginLeft: 5 }} icon={<UserOutlined />} /> */}
                    <span className="text-grey">สวัสดี, </span> {profile?.name} <UserOutlined style={{ marginLeft: 7 }} />
                </a>
            </Dropdown>
        </>
    )
}

export default observer(Profile)
