import React, { useState } from 'react'
import { Tabs } from 'antd';
import CodeBox from 'components/CodeBox';

import useBreadcrumb from 'hooks/useBreadcrumb'
import SettingsCommon from './setting.common';

const SettingsPage = () => {
    useBreadcrumb([
        { page: 'ตั้งค่า', link: '/settings', icon: '' }
    ])

    const [isChange, setIsChange] = useState(false);
    const [active, setActive] = useState('fee_type');

    const menu = [
        { name: 'ตั้งค่าทั่วไป', link: 'fee_type', icon: '', role: [], component: <SettingsCommon /> },
        // { name: 'ธนาคาร', link: 'bank', icon: '', role: [], component: <SettingBank /> },
        // { name: 'ตั้งค่าลายเซ็นและตราประทับ', link: 'signature', icon: '', role: [], component: <SettingSignature /> },
        // { name: 'ตั้งค่าหมู่บ้าน', link: 'village', icon: '', role: [], component: <SettingVillage /> },
        // { name: 'รีเซ็ท', link: 'reset', icon: '', role: [], component: <SettingReset /> },
    ];

    return (
        <>
            <CodeBox title="ตั้งค่า">
                <Tabs defaultActiveKey="fee_type" tabPosition="left" style={{ height: '100%' }} onChange={(activeKey) => setActive(activeKey)}>
                    {menu && menu.map((item) => (
                        <Tabs.TabPane tab={item.name} key={item.link} style={{ marginTop: '24px' }} disabled={Boolean(active !== item.link && isChange)}>
                            {React.cloneElement(item.component, { onIsChange: setIsChange })}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </CodeBox>
        </>
    )
}

export default SettingsPage
