import React, { useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Menu, Image } from "antd";
import {
    HomeOutlined,
    UserOutlined,
    CustomerServiceOutlined,
    HistoryOutlined,
    SettingOutlined,
} from "@ant-design/icons";

import { observer } from "mobx-react-lite";

// import * as auth from '../services/auth';

import { FALLBACK_BLANK_PNG } from 'utils/helpers'
// import dayjs from 'dayjs';

import useService from "../hooks/useService";

// var _ = require('lodash');

function SidebarLayout() {
    const { app, auth } = useService();
    // const currentPackage = mobxAuth.packageInfo;

    const subMenus = app.subMenus
    // const profile = mobxAuth.userInfo
    // const village = mobxAuth.villageInfo

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname !== "/chat") {
            app.setLayout('layout-space');
        }
        // eslint-disable-next-line
    }, [location.pathname])

    const ROLE = {
        'officer': 0,
        'admin': 1,
    }

    let menuLinks = [
        {
            name: "หน้าหลัก",
            url: "/",
            icon: <HomeOutlined />,
            role: [0, 1]
        },
        {
            name: "จัดการคำร้อง",
            url: "/complaints_management",
            role: [0, 1],
            icon: <CustomerServiceOutlined />,
            subitems: [
                // {
                //     name: "แผนที่",
                //     url: "/complaints_map",
                //     role: [0, 1],
                // },
                {
                    name: "คำร้อง",
                    url: "/complaints",
                    role: [0, 1]
                },
                // {
                //     name: "งานร้องเรียน",
                //     url: "/complaints_job",
                //     role: [0, 1],
                // },
            ]
        },
        // {
        //     name: (
        //         <div style={{ display: 'flex', alignItems: 'center' }}>
        //             ติดต่อเจ้าหน้าที่{' '} <Badge count={chat.unComplete ?? 0} style={{ marginLeft: 5 }} />
        //         </div>
        //     ),
        //     // name: `ติดต่อเจ้าหน้าที่`,
        //     url: "/chat",
        //     icon: <CommentOutlined />,
        //     role: [0, 1],
        //     disabled: true,
        // },
        {
            name: "จัดการผู้ใช้งาน",
            url: "/users_management",
            role: [1],
            icon: <UserOutlined />,
            subitems: [
                // {
                //     name: "เจ้าหน้าที่",
                //     url: "/officer",
                //     role: [1],
                //     // disabled: true,
                // },
                {
                    name: "ผู้ใช้งาน",
                    url: "/users",
                    role: [1],
                    // disabled: true,
                },
            ]
        },
        {
            name: "ตั้งค่าการใช้งาน",
            url: "/locations_management",
            role: [0, 1],
            icon: <SettingOutlined />,
            subitems: [
                // {
                //     name: "ตั้งค่าสถานที่",
                //     url: "/locations",
                //     role: [1],
                // },
                // {
                //     name: "ตั้งค่าแผนก",
                //     url: "/departments",
                //     role: [1],
                // },
                // {
                //     name: "ตั้งค่าตำแหน่งหน้าที่",
                //     url: "/jobs_title",
                //     role: [1],
                // },
                {
                    name: "ประเภทแจ้งเหตุและปัญหา",
                    url: "/issue_types",
                    role: [0, 1],
                },
                // {
                //     name: "ตั้งค่าประเภทแจ้งเหตุ",
                //     url: "/event_types",
                //     role: [1],
                // },
            ]
        },
        // {
        //     name: "ตั้งค่าระบบ",
        //     url: "/settings",
        //     icon: <SettingOutlined />,
        //     role: [1],
        //     disabled: true,
        // },
        {
            name: "ประวัติการใช้งาน",
            url: "/user_logs",
            icon: <HistoryOutlined />,
            role: [1],
        },
    ];

    const onOpenSubMenus = (subMenus) => {
        app.setOpenSubMenus(subMenus);
    }

    const handleRedirect = e => {
        navigate(e.key);
        // if (e.key === "/logout") {
        //     Modal.confirm({
        //         title: 'ต้องการออกจากจากระบบใช่หรือไม่ ?',
        //         icon: <ExclamationCircleOutlined />,
        //         content: '',
        //         okText: 'ยืนยัน',
        //         cancelText: 'ยกเลิก',
        //         onOk: handleLogout
        //     });
        // }
    }

    // const handleLogout = async () => {
    //     auth.authLogout();
    //     navigate('/', { replace: true });
    //     window.location.reload()
    // }

    const renderMenuItems = () => {

        return menuLinks?.filter((link) => link?.role?.includes(ROLE[auth.userInfo?.role]))?.map((link) => (
            (link?.subitems) ? ({
                ...link,
                label: link.name,
                key: link.url,
                icon: link.icon,
                children: link.subitems
                    // .filter(subItem => subItem?.role.indexOf(profile?.level) >= 0)
                    ?.filter((subItem) => subItem?.role?.includes(ROLE[auth.userInfo?.role]))
                    .map((subItem) => ({ ...subItem, label: subItem.name, key: subItem.url, icon: subItem.icon })),
            }) : ({
                ...link,
                label: link.name,
                key: link.url,
                icon: link.icon,
            })
        )
        )
    }

    return (
        <>
            <Link to="/">
                <AppLogoImageMobx />
            </Link>

            {/* <div className="underline"></div> */}
            {/* <Divider style={{ borderColor: 'white' }} /> */}
            <Menu
                theme="light"
                style={{ marginTop: '15px' }}
                onClick={handleRedirect}
                mode="inline"
                selectedKeys={[location.pathname]}
                defaultOpenKeys={subMenus}
                openKeys={subMenus}

                onOpenChange={(subMenus) => onOpenSubMenus(subMenus)}
                items={renderMenuItems()}
            />
        </>
    )
}

const AppLogoImage = () => {

    return (
        <div className="sidebar-logo" style={{ boxShadow: '0 1px 9px -3px rgba(0,0,0,.2)' }}>
            <div className="sidebar-default-logo">
                <Image
                    style={{ height: '64.25px', width: 'auto', maxWidth: '200px', objectFit: 'contain' }}
                    preview={false}
                    src={process.env.PUBLIC_URL + '/images/logo_full.jpg' ?? FALLBACK_BLANK_PNG}
                    fallback={FALLBACK_BLANK_PNG}
                />
            </div>

            <div className="sidebar-mini-logo">
                <Image
                    style={{ height: '64.25px' }}
                    preview={false}
                    src={process.env.PUBLIC_URL + '/images/logo_short.png' ?? FALLBACK_BLANK_PNG}
                    fallback={FALLBACK_BLANK_PNG}
                />
            </div>
        </div>
    )
}

const AppLogoImageMobx = observer(AppLogoImage);

export default observer(SidebarLayout)