import { Modal, Form, Input, Select, message, Row, Col } from 'antd';
import useConfirmCancel from 'hooks/useConfirmCancel'
import { usePrompt } from 'hooks/usePrompt'

import { NORMAL_REGEX } from 'utils/helpers';
import { getMsg } from 'utils/codeMsg'
import { axiosAPI } from 'http';
import { useEffect } from 'react';

export default function UserModalForm({ visible, onCancel, reload, data }) {
    const [cancel, setCancel, confirmCancel] = useConfirmCancel(onCancel)
    // const [checkPassword, setCheckPassword] = useState(false);

    usePrompt('Changes you made may not be saved.', cancel);

    // useEffect(() => {
    //     if (profile?.level === 9) {
    //         villages.fetchVillages().then(res => setVillagesData(res.data.data));
    //     }
    // }, [profile.level])
    console.log(data)

    let options = {};

    if (!data) {
        options = {
            title: 'เพิ่มผู้ใช้งาน',
            password: 'รหัสผ่าน',
            require: true,
        };
    } else {
        options = {
            title: 'แก้ไขผู้ใช้งาน',
            password: 'รหัสผ่าน (กรณีเปลี่ยนรหัสผ่าน)',
            require: false
        };
    }

    const onUpdate = (id, data) => {
        if (id == 1) {
            delete data?.username
        }

        axiosAPI.patch(`/api/v1/managers/${id}`, data)
            .then(res => {
                message.success(getMsg('UPDATE_SUCCESS'));
                reload();
                onCancel();
            })
            .catch(error => {
                message.error(getMsg(error.response.data.code));
            })
    }

    const onCreate = (data) => {
        axiosAPI.post('/api/v1/managers', data)
            .then(res => {
                message.success(getMsg('CREATE_SUCCESS'));
                reload();
                onCancel();
            })
            .catch(error => {
                message.error(getMsg(error.response.data.code));
            })
    }

    const onSubmit = (values) => {
        if (!values?.email) {
            values.email = undefined
        }

        if (!values?.password) {
            values.password = undefined
        }

        if (!values?.confirm) {
            values.confirm = undefined
        }

        if (!data) {
            values.enabled = true;
            onCreate(values);
        } else {
            // values.enabled = values.enabled === 'true' ? true : false;
            onUpdate(data.id, values);
        }
    };

    const [form] = Form.useForm();

    useEffect(() => {
        form?.setFieldsValue({
            ...data
        })
    }, []);

    return (
        <Modal
            width={700}
            visible={visible}
            title={options.title}
            okText="บันทึก"
            cancelText="ยกเลิก"
            // destroyOnClose={true}
            onCancel={confirmCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        // form.resetFields();
                        onSubmit(values);
                        // console.log(Object.fromEntries(Object.entries(obj).filter(([k, v]) => v !== null)))
                    })
                    .catch((info) => {
                        // console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="control-hooks"
                onValuesChange={() => setCancel(true)}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="ชื่อ - สกุล"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณากรอกชื่อ - สกุล'
                                }, {
                                    pattern: NORMAL_REGEX,
                                    message: "ไม่สามารถกรอกอักขระพิเศษชนิดนี้ได้"
                                },
                            ]}
                            initialValue={data?.name}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label="อีเมล"
                            rules={[
                                {
                                    type: 'email',
                                    required: !data ? true : false,
                                    message: 'กรุณากรอกอีเมล และกรอกให้ถูกต้อง'
                                },
                            ]}
                            initialValue={data?.email}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="username"
                            label="ชื่อผู้ใช้"
                            rules={[
                                {
                                    min: data?.id === 1 ? 0 : 6,
                                    message: 'กรุณากรอกชื่อผู้ใช้อย่างน้อย 6 ตัว',
                                },
                                {
                                    required: true,
                                    message: 'ชื่อผู้ใช้'
                                }, {
                                    pattern: NORMAL_REGEX,
                                    message: "ไม่สามารถกรอกอักขระพิเศษชนิดนี้ได้"
                                },
                            ]}
                            initialValue={data?.username}
                        >
                            <Input disabled={data?.id === 1} autoComplete="off" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="role"
                            label="ระดับ"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาเลือกระดับของผู้ใช้งาน',
                                },
                            ]}
                            initialValue={data?.role}
                        >
                            <Select
                                disabled={data?.id === 1}
                                placeholder="เลือกระดับของผู้ใช้งาน"
                            >
                                <Select.Option value="officer">Officer</Select.Option>
                                <Select.Option value="admin">Admin</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="is_enabled"
                            label="การใช้งาน"
                            initialValue={1}
                        >
                            <Select
                                placeholder=""
                                disabled={data?.id === 1}
                            >
                                <Select.Option value={1}>เปิดใช้งาน</Select.Option>
                                <Select.Option value={0}>ปิดใช้งาน</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal >
    );
};