import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as auth from '../services/auth';
import { Form, Input, Button, Checkbox, Row, Col, Card, message, Image } from 'antd';
import { getMsg } from '../utils/codeMsg'
import { NORMAL_REGEX } from 'utils/helpers';
import { axiosAPI } from 'http';

export default function ResetPasswordPage() {
    let location = useLocation();
    let [params] = useSearchParams();
    let navigate = useNavigate();
    const token = auth.getToken();
    const isActive = useMemo(() => location?.pathname === '/active', [location?.pathname]);

    const onFinish = async (values) => {
        try {
            const data = {
                token: params?.get?.('token'),
                email: values?.email,
                password: values?.password
            }

            const response = await axiosAPI.post(`/api/v1/auth/password`, data)
            auth.authLogout();
            navigate('/');
            window.location.reload();

        } catch (error) {
            message.error(getMsg(error?.response?.data?.code))
        }
    };

    const onFinishFailed = (errorInfo) => {
        message.error(getMsg('UNKNOW'))
    };

    return (
        <div className="login-container">
            {/* <div className="login-bg" style={{ backgroundImage: `url("/images/login-bg.jpg")` }}></div> */}
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col>
                    <Card bodyStyle={{ width: '400px', boxShadow: '0 0 100px rgb(0 0 0 / 8%)' }}>
                        <Row justify="center" style={{ marginBottom: '10px' }}>
                            <Col>
                                <div className="login-logo">
                                    <Image
                                        preview={false}
                                        src="/images/logo_short.png"
                                        style={{
                                            height: '150px',
                                            width: 'auto',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col className='text-center' span={24}>
                                <h1>
                                    เปลี่ยนรหัสผ่าน
                                </h1>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Form
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    layout='vertical'
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Form.Item
                                        label="อีเมล"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกชื่อเข้าใช้งาน',
                                            },
                                        ]}
                                        initialValue={params?.get?.('email')}
                                    >
                                        <Input size="large" disabled />
                                    </Form.Item>

                                    <Form.Item
                                        label="รหัสผ่าน"
                                        name="password"
                                        rules={[
                                            {
                                                min: 6,
                                                message: 'กรุณากรอกรหัสผ่านอย่างน้อย 6 ตัว',
                                            },
                                            {
                                                required: true,
                                                message: 'กรุณากรอกรหัสผ่าน',
                                            },
                                        ]}
                                        initialValue=""
                                    >
                                        <Input.Password size="large" />
                                    </Form.Item>

                                    <Form.Item
                                        name="confirm"
                                        label="ยืนยันรหัสผ่าน"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณายืนยันรหัสผ่าน',
                                            }, {
                                                pattern: NORMAL_REGEX,
                                                message: "ไม่สามารถกรอกอักขระพิเศษชนิดนี้ได้"
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error('ยืนยันรหัสผ่านไม่ถูกต้อง'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password size="large" autoComplete="off" />
                                    </Form.Item>

                                    <Form.Item >
                                        <Button size="large" className="login-button" block type="primary" htmlType="submit">
                                            เปลี่ยนรหัสผ่าน
                                            {/* {isActive ? 'เปิดใช้งานบัญชี' : 'เปลี่ยนรหัสผ่าน'} */}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
