import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'antd'
import dayjs from 'dayjs';
// import { MiniBar, Bar } from 'ant-design-pro/lib/Charts';
import { Column } from '@ant-design/plots';
// import { fetchSummary } from '../services/invoice'
// import { fetchChart } from '../services/dashboard'
// import { Link } from 'react-router-dom';
import { numFormat } from 'utils/helpers';
import { axiosAPI } from 'http';

const CardStatYear = () => {

    const date = dayjs();
    const [count, setCount] = useState(0);
    const [chart, setChart] = useState([]);

    useEffect(() => {
        axiosAPI.get("/api/v1/reports?type=number_of_issue_in_year",
            {
                params: {
                    year: date.get('year')
                }
            }
        )
            .then(res => {
                let _count = 0;
                const _data = res?.data?.map(v => {
                    let value = v?.value ?? 0;
                    _count += value;
                    return { label: dayjs(v?.name).format('MMM'), values: value }
                });
                setChart(_data);
                setCount(_count);
            });
        // fetchData()
        // eslint-disable-next-line
    }, [])

    // const fetchData = () => {
    //     const params = {
    //         year: date.year(),
    //         month: 0
    //     }
    //     Promise.all([fetchSummary(params), fetchChart(params, isYearly)])
    //         .then(([now, chart]) => {
    //             setNow(now.data)

    //             let chartData;
    //             if (isYearly) {
    //                 if (chart.data?.label?.length > 0) {
    //                     chartData = chart.data.label.slice(3, 8).map((item, idx) => {
    //                         let index = chart.data.label.findIndex(v => v === item);
    //                         return {
    //                             label: (item + 543).toString(),
    //                             values: parseFloat(chart.data.data[index]),
    //                         }
    //                     })
    //                 }
    //             } else {
    //                 chartData = chart.data?.map((item, idx) => {
    //                     return {
    //                         label: dayjs().month(idx).format('MMM'),
    //                         // x: dayjs().month(idx).format('MMMM BBBB'),
    //                         values: parseFloat(item),
    //                     }
    //                 })
    //             }

    //             setChart(chartData)
    //         });
    // }

    // useEffect(() => {
    //     fetchData()
    //     // eslint-disable-next-line
    // }, [])

    return (
        <>
            <Card style={{ height: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col span={12} className="text-grey">จำนวนคำร้องในช่วง 1 ปี</Col>
                    <Col span={12} className="text-right" >
                        {/* <Link to="/common_fee_report" style={{ color: 'black' }}>{date.format('MMMM')}</Link> */}
                        {date.format('BBBB')}
                    </Col>
                    {/* <Col span={12} className="text-right" >{date.format('MMMM')}</Col> */}
                </Row>

                <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                    <Col span={24} style={{ fontSize: '26px' }}>{numFormat(count)}</Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24} >
                        {/* <MiniBar height={45} data={chart} color="#945de2" /> */}
                        {/* <Bar height={100} data={chart} color="#945de2" style={{ maxHeight: '100%' }} /> */}
                        <BarChart data={chart} />
                    </Col>
                </Row>

                {/* <Card.Grid hoverable={false} style={{ width: '100%' }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Space size={8}>
                                <div>ในปี <span className="text-lg">พ.ศ. {date.format('BBBB')}</span></div>
                            </Space>
                        </Col>
                    </Row>
                </Card.Grid> */}
            </Card>
        </>
    )
}

const BarChart = ({ data }) => {
    const config = {
        data,
        xField: 'label',
        yField: 'values',
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
            autoHide: true,
            content: ''
            // rotate: true
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
            },
        },
        yAxis: false,
        // height: 95,
        color: '#8F3535',
        meta: {
            // type: {
            //     alias: 'xx',
            // },
            values: {
                alias: 'จำนวน',
                formatter: (v) => numFormat(v, 2)
            },
        },
    };
    return <Column {...config} />;
};

export default CardStatYear
