import { useEffect } from "react";
import useService from "./useService";

export default function useBreadcrumb(items, reload) {
    const { app } = useService();

    useEffect(() => {
        app.setBreadcrumbs(items)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
}