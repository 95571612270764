import dayjs from "dayjs";

import * as chatAPI from './chat';
import { getToken } from './auth';
import { action, makeObservable, observable } from "mobx";

// import AuthService from "./auth.mobx";

// const authService = new AuthService();

export class WebsocketMessageEvent {
    static RequestLineMessage = 'REQUEST_LINE_MESSAGE';
    static SendingLineMessage = 'SENDING_LINE_MESSAGE';
    static IncomingLineMessage = 'INCOMING_LINE_MESSAGE';
}

export default class ChatService {
    members = {
        data: new Map(),
        page: 1,
        total: 0,
        error: null,
    }

    sessions = {
        data: [],
        total: 0,
        error: null,
        loading: true,
    };

    messages = {
        data: [],
        total: 0,
        started_at: '',
        error: null,
    };

    state = {
        closed: true,
        member: null,
        session: null,
        memberId: null,
        sessionId: null,
    };

    unComplete = 0;
    lastMessageUpdatedAt = '';

    onMessage = null;
    websocket = null;

    villageId = null;

    constructor() {
        makeObservable(this, {
            // properties
            state: observable,
            members: observable,
            sessions: observable,
            messages: observable,
            lastMessageUpdatedAt: observable,
            unComplete: observable,

            // member
            setMember: action,
            setMembers: action,
            setMemberError: action,

            setSessions: action,
            setSessionsError: action,
            setSessionsLoading: action,

            setMessages: action,
            setMessagesError: action,

            joinRoom: action,
            setSession: action,
            // getMember: action,
            initMembers: action,
            // listMembers: action,
            initSessions: action,
            // listSessions: action,
            initMessages: action,
            // listMessages: action,

            closeSession: action,
            websocketOnMessage: action,

            setVillageId: action,
            setUnComplete: action
        });

        // console.log(this.villageId, this.accessToken);

        // if (this.villageId && this.accessToken) {
        //     this.websocketConnect();
        // }
    }

    get accessToken() {
        return getToken()?.access_token ?? '';
    }

    // get villageId() {
    //     return authService?.village_id ?? null;
    // }

    setVillageId = (auth) => {
        this.villageId = auth?.village_id;
    }

    websocketConnect = () => {
        // console.log('connect');
        if (!this.villageId) return;

        this.websocket = new WebSocket(`${process.env.REACT_APP_BASE_WS}/villages/${this.villageId}/chats?token=${this.accessToken}`);
        this.websocket.onclose = this.websocketOnClose;
        this.websocket.onerror = this.websocketOnError;
        this.websocket.onmessage = this.websocketOnMessage;
    }

    websocketOnMessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.event === WebsocketMessageEvent.IncomingLineMessage) {
            if (this.state.session?.id === data.session_id) {
                this.messages.data.push({ id: data.id, type: data.type, body: data.body, responsed: false, created_at: dayjs().format() });
                this.lastMessageUpdatedAt = dayjs().format();
            }
            return this.getMemberWithMessageEvent(data.member_id);
        }

        if (data.event === WebsocketMessageEvent.SendingLineMessage) {
            if (this.state.session?.id === data.session_id) {
                this.messages.data.push({ id: data.id, type: data.type, body: data.body, responsed: true, created_by: data.created_by, created_at: dayjs().format() });
                this.lastMessageUpdatedAt = dayjs().format();
            }
            return this.getMember(data.member_id);
        }

        if (data.event === WebsocketMessageEvent.RequestLineMessage) {
            if (this.state.session?.id) {
                this.initSessions();
                this.listSessions();
                this.lastMessageUpdatedAt = dayjs().format();
            }
            return this.getMemberWithMessageEvent(data.member_id, data.body);
        }
    }

    websocketOnClose = (event) => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', event.reason);
        setTimeout(() => this.websocketConnect(), 1000);
    }

    websocketOnError = (error) => {
        console.error('Socket encountered error: ', error.message, 'Closing socket');
        this.websocket.close();
    }

    joinRoom = (member) => {
        if (this.state.member?.id !== member?.id) {
            this.state.member = member;
            this.initMessages();
            this.initSessions();
            this.listSessions(true);
        }
    }

    setSession = (session) => {
        // if (this.state.session?.id !== session?.id) {
        // }
        this.state.session = session;
        this.state.closed = !!session.closed_at;
        this.initMessages();
        this.listMessages();
    }

    getMember = (memberId) => {
        chatAPI.getMember(memberId)
            .then((response) => this.setMember(response.data))
            .catch((error) => this.setMemberError(error))
    }

    getMemberWithMessageEvent = (memberId, overrideBody = null) => {
        chatAPI.getMember(memberId)
            .then((response) => {
                const data = response.data;

                if (overrideBody) {
                    data.message = overrideBody
                };

                this.setMember(data);
                this.onMessage?.(data);
            })
            .catch((error) => this.setMemberError(error))
    }

    setMember = (data) => {
        this.members.data.set(data.id, data);
        this.members.data = new Map([...this.members.data].sort((x, y) => dayjs(y[1].opened_at).diff(x[1].opened_at)));
    }

    setMemberError = (error) => {
        this.members.error = error;
    }

    setMembers = (result) => {
        result.data.forEach(v => {
            this.members.data.set(v.id, v);
        });

        this.members.total = result.total;
    }

    initMembers = () => {
        this.members.page = 1;
        this.members.data.clear();
        this.members.total = 1;
        this.members.error = null;
    }

    listMembers = () => {
        chatAPI.listMembers(Math.ceil(this.members.data.size / 15) + 1)
            .then(response => this.setMembers(response.data))
            .catch((error) => this.setMemberError(error));
    }

    setSessions = (result, join = false) => {
        if (this.sessions.total !== result.total) {
            this.sessions.data = [];
        }

        this.sessions.data.push(...result.data);
        this.sessions.total = result.total;

        if (join) {
            this.setSession(result.data[0]);
        }
    }

    setSessionsError = (error) => {
        this.sessions.error = error;
    }

    setSessionsLoading = (loading) => {
        this.sessions.loading = loading;
    }

    initSessions = () => {
        this.sessions.page = 1;
        this.sessions.data = [];
        this.sessions.total = 0;
        this.sessions.error = null;
    }

    listSessions = (join = false) => {
        this.setSessionsLoading(true);
        chatAPI.listSessions(this.state.member?.id, this.sessions.page++)
            .then(response => this.setSessions(response.data, join))
            .catch((error) => this.setSessionsError(error))
            .finally(() => this.setSessionsLoading(false));
    }

    setMessages = (result) => {
        if (this.messages.total !== result.total) {
            this.messages.data = [];
        }

        this.messages.data.push(...result.data);
        this.messages.total = result.total;
        this.lastMessageUpdatedAt = dayjs().format();
    }

    setMessagesError = (error) => {
        this.messages.error = error;
    }

    initMessages = () => {
        this.state.closed = true;
        this.messages.page = 1;
        this.messages.data = [];
        this.messages.total = 0;
        this.messages.error = null;
        this.messages.started_at = dayjs().format();
    }

    listMessages = () => {
        if (this.messages.total > 0 && this.messages.data.length >= this.messages.total) {
            return;
        }

        chatAPI.listMessages(this.state.member?.id, this.state.session?.id, this.messages.started_at, this.messages.page++)
            .then((response) => this.setMessages(response.data))
            .catch((error) => this.setMessagesError(error));
    }

    sendMessage = async (memberId, message) => {
        return chatAPI.sendMessage(memberId, message);
    }

    closeSession = (join = false) => {
        return chatAPI.closeSession(this.state.member?.id ?? 0)
            .then(() => {
                this.initSessions();
                this.listSessions(true);
                this.initMessages();
                this.listMessages();
                this.getUnComplete();
            });
    }

    getUnComplete = () => {
        chatAPI.getUncomplete()
            .then((response) => this.setUnComplete(response.data.value))
    }

    setUnComplete = (value) => {
        this.unComplete = value;
    }
}