import React from "react";

import ChatService from "services/chat.mobx";
// import UserService from "services/user.mobx";
// import MemberService from "services/member.mobx";
import AuthService from "services/auth.mobx";
import AppService from "services/app.mobx";
import ModalService from "services/modal.mobx";

export const services = {
    chat: new ChatService(),
    // user: new UserService(),
    // member: new MemberService(),
    auth: new AuthService(),
    app: new AppService(),
    modal: new ModalService()
};

const ServiceContext = React.createContext(services);

export default ServiceContext;