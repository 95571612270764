import React, { useEffect } from 'react'
import {
    Routes,
    Route,
    useLocation
} from "react-router-dom";

import useService from 'hooks/useService';

import LoadingPage from 'pages/LoadingPage';
// import LoginPage from 'pages/LoginPage';
import DefaultLayout from 'layouts/DefaultLayout';
// import ChatPage from 'pages/chat/ChatPage';
import ComplaintsPage from 'pages/complaints/index.page';
import ProfilePage from 'pages/profile/ProfilePage';
import NotFoundPage from 'pages/NotFoundPage';
import SettingsPage from 'pages/settings/SettingsPage';
import UsersPage from 'pages/users/UsersPage';
import UserLogsPage from 'pages/user_logs/UserlogsPage';
import IndexPage from 'pages/index/index.page';
// import ComplaintsMapPage from 'pages/complaints_map/index.page';
// import ComplaintTypesPage from 'pages/complaint_types/index.page';
import ComplaintDetailPage from 'pages/complaints/complaints.detail.page';
import LocationsPage from 'pages/locations/index.page';
import LocationsDetailPage from 'pages/locations/locations.detail.page';
import DepartmentsPage from 'pages/departments/index.page';
import JobsTitlePage from 'pages/jobs_title/index.page';
import RepairTypesPage from 'pages/issue_types/index.page';
import MaidTypesPage from 'pages/event_types/index.page';
import OfficerPage from 'pages/officer/index.page';
import LogsPage from 'pages/user_logs/index.page';
import LoginPage from 'pages/LoginPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import { observer } from 'mobx-react-lite';

const ROLE = {
    'officer': 0,
    'admin': 1,
}

const ROUTES = [
    // { path: '/', component: <IndexPage />, role: [0, 1, 9] },
    // { path: '/chat', element: <ChatPage /> },
    { path: '/complaints', element: <ComplaintsPage />, role: [0, 1] },
    { path: '/complaints/:complaint_id', element: <ComplaintDetailPage />, role: [0, 1] },
    // { path: '/complaints_map', element: <ComplaintsMapPage />, role: [0, 1] },
    // Use Setting
    { path: '/locations', element: <LocationsPage />, role: [0, 1] },
    { path: '/locations/:locationId', element: <LocationsDetailPage />, role: [0, 1] },
    { path: '/departments', element: <DepartmentsPage />, role: [0, 1] },
    { path: '/jobs_title', element: <JobsTitlePage />, role: [0, 1] },
    { path: '/issue_types', element: <RepairTypesPage />, role: [0, 1] },
    { path: '/event_types', element: <MaidTypesPage />, role: [0, 1] },

    // { path: '/setting_complaint_types', element: <ComplaintTypesPage />, role: [0, 1] },
    { path: '/officer', element: <OfficerPage />, role: [0, 1] },
    { path: '/users', element: <UsersPage />, role: [1] },
    { path: '/user_logs', element: <LogsPage />, role: [1] },
    { path: '/settings', element: <SettingsPage />, role: [0, 1] },

    { path: '/profile', element: <ProfilePage />, role: [0, 1] },
    // { path: '/members', component: MembersPage },
];

const RouteApp = () => {

    const location = useLocation();
    const { auth } = useService();

    useEffect(() => {
        auth.fetchStarterInfo();
    }, [])

    if (auth.ready === false) {
        return <LoadingPage />;
    }

    if (['/password']?.includes(location?.pathname)) {
        return <ResetPasswordPage />;
    }

    if (auth.isLoggedIn === false) {
        return <LoginPage />;
    }

    return (
        <Routes>
            <Route path="/" element={<DefaultLayout />}>
                <Route index element={<IndexPage />} />
                {ROUTES?.filter((v) => v?.role?.includes(ROLE[auth.userInfo?.role])).map(v => <Route key={v.path} path={v.path} element={v.element} />)}
            </Route>
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}

export default observer(RouteApp)