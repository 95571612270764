import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Space } from 'antd'
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
// import { fetchComplaint } from '../services/dashboard'
import { Decimal } from 'decimal.js';
import { numFormat } from 'utils/helpers'
import { axiosAPI } from 'http';

const CardComplaint = () => {

    const date = dayjs()
    const [now, setNow] = useState(null);
    const [prev, setPrev] = useState(null);

    const fetchData = () => {
        // Promise.all([
        //     fetchComplaint({ month: date.month() + 1, year: date.year() }),
        //     fetchComplaint({ month: date.month(), year: date.year() })])
        //     .then(([now, prev]) => {
        //         setNow(now.data)
        //         setPrev(prev.data)
        //     });
    }

    useEffect(() => {
        // fetchData()
        Promise.all([
            axiosAPI.get("/api/v1/issues?page=1&take=1"),
            axiosAPI.get("/api/v1/issues?page=1&take=1&filter[status][]=1"),
            axiosAPI.get("/api/v1/issues?page=1&take=1&filter[status][]=2"),
            axiosAPI.get("/api/v1/issues?page=1&take=1&filter[status][]=3"),
        ])
            .then(([a, b, c, d]) => {
                setNow({
                    total: a.data.total ?? 0,
                    received: b.data.total ?? 0,
                    ongoing: c.data.total ?? 0,
                    completed: d.data.total ?? 0,
                });
            })
            .catch(function () {
                setNow({
                    total: 300,
                    received: 100,
                    ongoing: 100,
                    completed: 100,
                });
            });

        setPrev({ total: 500 });
        // eslint-disable-next-line
    }, [])

    const complete = new Decimal(now?.completed || 0).mul(100).dividedBy(now?.total || 0);

    return (
        <>
            <Card>
                <Card.Grid hoverable={false} style={{ width: '100%', height: '150px' }}>
                    <Row gutter={[16, 16]} justify="space-between">
                        <Col className="text-grey">จำนวนคำร้อง</Col>
                        <Col className="text-right" ><Link to="/complaints" style={{ color: 'black' }}>{date.format('MMMM')}</Link></Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                        <Col span={12}>
                            <Space direction="vertical" size={0}>
                                <div style={{ fontSize: '26px' }}>{numFormat(now?.received)}</div>
                                <div className="text-grey" style={{ fontSize: '10px' }}>รอดำเนินการ</div>
                            </Space>
                        </Col>
                        <Col span={12}>
                            <Space direction="vertical" size={0}>
                                <div className="text-primary" style={{ fontSize: '26px' }}>{numFormat(now?.ongoing)}</div>
                                <div className="text-grey" style={{ fontSize: '10px' }}>กำลังดำเนินการ</div>
                            </Space>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={24} className="text-dark-grey">
                            <Space size={8} align="end">
                                <div>แล้วเสร็จ</div>
                                <div className="text-primary text-lg" style={{ fontSize: '16px' }}>{numFormat(now?.completed)}</div>
                                <div>รายการคิดเป็น</div>
                                <div className="text-primary text-lg" style={{ fontSize: '16px' }}>{numFormat(complete.toFixed(2), 2)}%</div>
                            </Space>
                        </Col>
                    </Row>
                </Card.Grid>

                <Card.Grid hoverable={false} style={{ width: '100%' }}>
                    <Row gutter={[16, 16]} justify="space-between">
                        <Col>
                            <div>
                                <span className="text-dark-grey">ในเดือน </span><span className="text-lg">{date.subtract(1, 'month').format('MMMM')}</span>
                            </div>
                        </Col>
                        <Col className="text-right">
                            <div><span className="text-lg">{numFormat(prev?.total)}</span> <span className="text-dark-grey">รายการ</span></div>
                        </Col>
                    </Row>
                </Card.Grid>

            </Card>
        </>
    )
}

export default CardComplaint
