import React from 'react'
import { Button, Card, Col, Row, Space } from 'antd'
import {
    PlusCircleOutlined,
} from "@ant-design/icons";
import { observer, useLocalObservable } from 'mobx-react-lite';

import useBreadcrumb from 'hooks/useBreadcrumb'
import LocationsTable from './locations.table';
import { useLocationCreateModal, useLocationEditModal } from './locations.form';
import { axiosAPI } from '../../http';
import useConfirm from 'hooks/useConfirm';

const LocationsPage = () => {

    useBreadcrumb([
        { page: 'จัดการสถานที่', link: '/locations', icon: '' }
    ])

    const state = useLocalObservable(() => ({
        reload: null,
        setReload() {
            this.reload = Date.now()
        }
    }));

    const handleCreate = useLocationCreateModal(async (values) => {
        // await axiosAPI.post(`/api/v1/issue-types`, values);
        await state.setReload();
    })

    const handleEdit = useLocationEditModal(async (values) => {
        // await axiosAPI.patch(`/api/v1/issue-types/${values.id}`, values);
        await state.setReload();
    })

    const handleDelete = useConfirm({
        onConfirm: async (id) => {
            // await axiosAPI.delete(`/api/v1/issue-types/${id}`);
            await state.setReload();
        },
    });

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col md={{ span: 24 }} lg={{ span: 24 }}>
                    <Card
                        title="ตั้งค่าสถานที่"
                        extra={(
                            <Space>
                                <Button ghost type="primary" onClick={() => handleCreate()} icon={<PlusCircleOutlined />}>เพิ่มสถานที่</Button>
                            </Space>
                        )}
                    >
                        <LocationsTable onEdit={handleEdit} onDelete={handleDelete} reload={state.reload} />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default observer(LocationsPage)