import RouteApp from 'route';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <RouteApp />
    </BrowserRouter>
  )
}

export default App;
