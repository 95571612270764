import React from 'react';
import { makeObservable, observable, action } from "mobx";

export default class ModalService {
    initModal = {
        show: false,
        header: React.createElement('div'),
        content: React.createElement('div'),
        footer: null,
        size: 500,
        okText: "บันทึก",
        cancelText: "ยกเลิก",
        okButtonProps: {},
        cancelButtonProps: {},

        onOk: () => console.log('ok'),
        onCancel: () => {
            this.closeModal();
        },
    }

    constructor() {
        makeObservable(this, {
            initModal: observable,

            setModal: action,
            clearModal: action,
            closeModal: action,
        });
    }

    setModal(data) {
        this.initModal = data;
    }

    closeModal() {
        this.initModal.show = false;
    }

    clearModal() {
        this.initModal = {
            show: false,
            header: React.createElement('div'),
            content: React.createElement('div'),
            footer: null,
            size: 500,
            okText: "บันทึก",
            cancelText: "ยกเลิก",

            onOk: () => console.log('ok'),
            onCancel: () => {
                this.closeModal();
            }
        };
    }
}