import React, { useEffect, useState } from 'react'
import { Modal, Switch } from 'antd'
import {
    ExclamationCircleOutlined,
} from "@ant-design/icons";

const MySwitch = (props) => {
    const { id, enabled = false, toggleFunc } = props;
    const [status, setStatus] = useState(false);

    useEffect(() => {
        setStatus(enabled)
    }, [enabled])

    const handleChangeStatus = (status) => {
        if (!status) {
            Modal.confirm({
                title: 'ต้องการปิดการใช้งานใช่หรือไม่ ?',
                icon: <ExclamationCircleOutlined />,
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
                onOk() {
                    toggleFunc(id, false)
                    setStatus(false)
                },
                onCancel() {
                    setStatus(true)
                },
            });
        } else {
            toggleFunc(id, true)
            setStatus(true)
        }
    }

    return <Switch checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน" checked={status} onClick={handleChangeStatus} />
}

export default MySwitch
