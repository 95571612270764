import React from 'react'
import { Modal, Row, Col } from 'antd'
import { CodeBoxContent } from './CodeBox';

const ModalLog = ({ visible, onCancel }) => {
    return (
        <>
            <Modal
                width={600}
                visible={visible}
                title={`เวอร์ชั่น ${process.env.REACT_APP_VERSION}`}
                cancelText="ปิด"
                onCancel={onCancel}
                okText={false}
                okButtonProps={{
                    style: {
                        display: "none",
                    },
                }}
            >
                <Row>
                    <Col span={24} style={{ maxWidth: '100%', maxHeight: '500px', overflowY: 'scroll' }}>
                        <div>
                            <CodeBoxContent title="เวอร์ชั่น 0.0.1" style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <span className="text-lg">ฟีเจอร์ใหม่</span><br />
                                - เริ่มต้นโปรเจกต์<br />
                                - จำลองข้อมูล

                                {/* <span className="text-lg">อัพเดท / แก้ไข</span><br />
                                - ปรับปรุงการแสดงผลข้อมูลลูกบ้าน<br />
                                - ปรับปรุงการตั้งค่า<br /> */}
                            </CodeBoxContent>

                            <CodeBoxContent title="เวอร์ชั่น 0.0.2" style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <span className="text-lg">ฟีเจอร์ใหม่</span><br />
                                - เพิ่มผู้ใช้งาน และรีเซ็ตรหัสผ่าน<br />
                            </CodeBoxContent>

                            <CodeBoxContent title="เวอร์ชั่น 0.0.3" style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <span className="text-lg">ฟีเจอร์ใหม่</span><br />
                                - เพิ่มประวัติการใช้งาน<br />
                                <span className="text-lg">อัปเดต / แก้ไข</span><br />
                                - ปรับการแสดงผลคำร้อง<br />
                            </CodeBoxContent>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default ModalLog;