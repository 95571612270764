export const CODE = {
    // CRUD______________________________________________________
    CREATE_SUCCESS: 'เพิ่มข้อมูลสำเร็จ',
    CREATE_FAIL: 'เพิ่มข้อมูลไม่สำเร็จ',
    UPDATE_SUCCESS: 'อัพเดทข้อมูลสำเร็จ',
    UPDATE_FAIL: 'อัพเดทข้อมูลไม่สำเร็จ',
    DELETE_SUCCESS: 'ลบข้อมูลสำเร็จ',
    DELETE_FAIL: 'ลบข้อมูลไม่สำเร็จ',
    CANCEL_SUCCESS: 'ยกเลิกสำเร็จ',
    CANCEL_FAIL: 'ยกเลิกไม่สำเร็จ',
    
    // Auth_____________________________________________________
    PASSWORD_MISMATCH: 'รหัสผ่านปัจจุบันไม่ถูกต้อง',

    // Broadcast_____________________________________________________
    GET_LIMIT_FAIL: 'เกิดปัญหาในการแสดงข้อมูลยอดคงเหลือ',

    // House_____________________________________________________
    HOUSE_TRANSFER_SUCCESS: 'โอนกรรมสิทธิ์สำเร็จ',
    HOUSE_FORMAT_SUCCESS: 'ล้างข้อมูลบ้านสำเร็จ',
    ERROR_EMPTY_HOUSE_TYPES: 'ขออภัยกรุณาเพิ่มประเภทบ้านก่อน',

    // Member_____________________________________________________
    MEMBER_FORMAT_SUCCESS: 'ล้างข้อมูลลูกบ้านสำเร็จ',
    
    // Faq_____________________________________________________
    FAQ_DATABASE_ERROR_FK_CONSTRAINT_FAILED: 'ไม่สามารถลบได้เนื่องจากข้อมูลนี้มีรายการย่อยอยู่',

    // Common Fee_________________________________________________
    COMMON_FEE_FETCH_FAIL: 'เกิดข้อผิดพลาดไม่สามารถดึงข้อมูลการแจ้งชำระได้',
    COMMON_FEE_PAY_FAIL: 'เกิดข้อผิดพลาดในการจ่ายกรุณาลองใหม่อีกครั้ง',

    // Village Setting____________________________________________
    VILLAGE_SETUP_SUCCESS: 'เซ็ทอัพสำเร็จ',
    VILLAGE_SETUP_FAIL: 'เซ็ทอัพไม่สำเร็จ',

    // Login______________________________________________________
    DISABLED_VILLAGE: 'ขออภัยระบบของคุณถูกระงับการใช้งาน',
    INCORECT_CREDENTIAL: 'อีเมล หรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
    // Login-Package______________________________________________
    NO_PACkAGE: 'ขออภัยไม่พบแพ็คเกจ หรือระบบของคุณอาจถูกระงับการใช้งาน',
    VILLAGE_UNVAILABLE: 'ขออภัยแพ็คเกจของคุณยังไม่พร้อมใช้งาน',
    PACKAGE_EXPIRED: 'ขออภัยไม่สามารถเข้าใช้งานได้เนื่องจากแพ็คเกจของคุณหมดอายุแล้ว',
    NOT_ALLOW: 'ขออภัยไม่อนุญาตให้คุณทำสิ่งนี้',
    REACHED_LIMIT: 'ขออภัยเกินกำหนด',

    // Confirm
    CONFIRM_PASSWORD_INCORRECT: 'ยืนยันรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',

    // Status
    STATUS_UPDATE_SUCCESS: 'ปรับสถานะสำเร็จ',
    STATUS_UPDATE_FAIL: 'ปรับสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',

    // Upload
    UPLOAD_SUCCESS: 'อัพโหลดข้อมูลสำเร็จ',
    UPLOAD_FAIL: 'เกิดข้อผิดพลาดในการอัพโหลดไฟล์กรุณาลองใหม่อีกครั้ง',
    UPLOAD_SELECT_FILE: 'กรุณาเลือกไฟล์',
    UPLOAD_NOT_ALLOW: 'ไม่อยู่ในชนิดไฟล์ที่สามารถอัพโหลดได้',
    UPLOAD_OVERSIZE: 'ขออภัยไฟล์มีขนาดใหญ่เกินที่กำหนด',

    INTERNAL_ERROR: 'ขออภัยเกิดข้อผิดพลาดจากระบบกรุณาติดต่อเจ้าหน้าที่',
    UNKNOW: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง'
}

export function getMsg(name, value) {
    let some = CODE[name];

    if (!some) {
        return 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง หรือแจ้งเจ้าหน้าที่';
    }

    if (typeof some === 'string') {
        return some;
    }

    if (typeof some === 'function') {
        return some(value);
    }

    return null;
}