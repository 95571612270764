import React, { useCallback } from 'react'
import Modal from 'antd/lib/modal';
import message from 'antd/lib/message';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { getMsg } from '../utils/codeMsg';

const useConfirm = ({ title = "ต้องการลบใช่หรือไม่ ?", content = "", icon = <ExclamationCircleOutlined />, type = 'delete', successMsg = '', failMsg = '', onConfirm = () => { } }) => {

    const TYPE = {
        create: { success: 'CREATE_SUCCESS', fail: 'CREATE_FAIL' },
        update: { success: 'UPDATE_SUCCESS', fail: 'UPDATE_FAIL' },
        delete: { success: 'DELETE_SUCCESS', fail: 'DELETE_FAIL' }
    }

    return useCallback((data) => {
        Modal.confirm({
            title: title,
            icon: icon,
            content: content,
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onOk: () => {
                onConfirm(data)
                    .then(res => {
                        successMsg ? message.success(successMsg) : message.success(getMsg(TYPE[type].success));
                    })
                    .catch(() => {
                        failMsg ? message.error(failMsg) : message.error(getMsg(TYPE[type].fail));
                    })
            }
        })
        // eslint-disable-next-line
    }, [onConfirm, title, icon, type])
}

export default useConfirm
