import React, { useState } from 'react'
import { Row, Col, Button, Form, InputNumber } from 'antd'

import { usePrompt } from 'hooks/usePrompt'
import { CodeBoxContent } from 'components/CodeBox';

const SettingsCommon = ({ onIsChange }) => {

    const [cancel, setCancel] = useState(false)

    usePrompt('Changes you made may not be saved.', cancel);

    const handleIsChange = (boolean) => {
        setCancel(boolean)
        onIsChange(boolean)
    }

    // const fetchPresets = useCallback(async () => {
    //     const resp = await getPresets(village_id);
    //     handleChangeType(resp?.data?.calculate?.type)

    //     // Calculate Setting
    //     let calculate = resp?.data?.calculate;

    //     form.setFieldsValue({
    //         type: Number.isInteger(calculate?.type) ? calculate?.type : null,
    //         percentage: calculate?.percentage ? (calculate?.percentage * 100) : 0,
    //         rate: calculate?.rate ? (calculate?.rate * 100) : null,
    //         outer: calculate?.outer ? calculate?.outer : null,
    //         full: calculate?.full === true ? 'true' : 'false',
    //         vat: typeof resp?.data?.vat === 'string' ? new Decimal(resp.data.vat).mul(100).toFixed(2) : 0,
    //         end_month: resp?.data?.end_month ?? 0,
    //         start_fine: resp?.data?.start_fine ?? 0,
    //     })
    //     // eslint-disable-next-line
    // }, [village_id])

    // useEffect(() => {
    //     fetchPresets()
    // }, [fetchPresets])

    const [form] = Form.useForm();

    const onSubmit = (values) => {
        let obj = {};
        // console.log(obj)
        onUpdate(obj)
    }

    const onUpdate = (data) => {
        // updatePresets(village_id, data)
        //     .then(res => {
        //         message.success(getMsg('UPDATE_SUCCESS'))

        //         auth.fetchVillageSettingInfo();
        //         // fetchPresets()
        //         handleIsChange(false)
        //     })
        //     .catch(() => message.error(getMsg('UPDATE_FAIL')))
    }

    const formCol = { xs: 24, md: 24, lg: 12, xl: 6 }; // col span

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onValuesChange={() => handleIsChange(true)}
            >
                <CodeBoxContent title="ตั้งค่าทั่วไป">
                    <Row gutter={[16, 16]}>
                        <Col {...formCol}>
                            <Form.Item
                                name="vat"
                                label="xxxx"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุภาษี'
                                    },
                                ]}
                            >
                                <InputNumber autoComplete="off" style={{ width: '100%' }} min={0} max={100} />
                            </Form.Item>
                        </Col>
                    </Row>
                </CodeBoxContent>
            </Form>

            <CodeBoxContent>
                <Row gutter={[16, 16]} style={{ marginBottom: '8px' }}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button
                            style={{ width: '150px' }}
                            size="large"
                            type="primary"
                            onClick={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        onSubmit(values);
                                    })
                                    .catch((info) => {
                                        // console.log('Validate Failed:', info);
                                    });
                            }}
                            disabled={!cancel}
                        >
                            บันทึก
                        </Button>
                    </Col>
                </Row>
            </CodeBoxContent>
        </>
    )
}

export default SettingsCommon
