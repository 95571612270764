import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, Tabs, Badge, Space } from 'antd'

import useBreadcrumb from 'hooks/useBreadcrumb'
import ComplaintsTable from './complaints.table'
import { statusTag } from './utils'
import { axiosAPI } from '../../http'

const ComplaintsPageInternal = () => {
    const [tab, setTab] = useState(0);

    // all, new, ongoing
    const [data, setData] = useState([0, 0, 0, 0]);

    const fetcher = useCallback(function () {
        Promise.all([
            axiosAPI.get("/api/v1/issues?page=1&take=1"),
            axiosAPI.get("/api/v1/issues?page=1&take=1&filter[status][]=1"),
            axiosAPI.get("/api/v1/issues?page=1&take=1&filter[status][]=2"),
        ])
            .then(([a, b, c]) => {
                setData(() => [a.data.total ?? 0, b.data.total ?? 0, c.data.total ?? 0, 0]);
            });
    }, []);

    const handleIssueCount = useCallback(function (i, v) {
        setData(function (prev) {
            const clone = [...prev];
            clone[i] = v;
            return clone;
        });
    }, []);

    const tabList = useMemo(function () {
        return [
            { key: 0, title: 'ทั้งหมด', component: <ComplaintsTable /> },
            { key: 1, title: 'รอดำเนินการ', component: <ComplaintsTable status={1} /> },
            { key: 2, title: 'กำลังดำเนินการ', component: <ComplaintsTable status={2} /> },
            { key: 3, title: 'เสร็จสิ้น', component: <ComplaintsTable status={3} /> },
        ];
    }, []);

    useEffect(() => {
        fetcher();
    }, [fetcher]);

    return (
        <Tabs onChange={(key) => setTab(key)} destroyInactiveTabPane>
            {tabList.map(row => (
                <Tabs.TabPane
                    key={row.key}
                    tab={
                        <Space>
                            <span>{row.title}</span>
                            <Badge count={data?.[row.key] ?? 0} style={{ backgroundColor: '#8F3535' }} />
                        </Space>
                    }
                >
                    {React.cloneElement(row.component, { index: row.key, onLoaded: handleIssueCount })}
                </Tabs.TabPane>
            ))}
        </Tabs>
    )
}

const ComplaintsPage = function () {
    useBreadcrumb([
        { page: 'รายการคำร้อง', link: '/complaints', icon: '' }
    ]);

    return <ComplaintsPageInternal />
}

export default ComplaintsPage