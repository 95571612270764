import React from 'react'

const boxStyle = {
    backgroundColor: '#fff',
    border: '1px solid #f0f0f0',
    borderRadius: '2px',
    display: 'inline-block',
    margin: '0 0 16px',
    position: 'relative',
    width: '100%',
}

const boxMetaStyle = {
    borderTop: '1px solid #f0f0f0',
    fontSize: '14px',
    position: 'relative',
    width: '100%',
    // marginTop: '24px'
}

const boxTitleStyle = {
    fontSize: '16px',
    fontWeight: '500',
    marginLeft: '16px',
    padding: '1px 8px',
    top: '-14px',
    position: 'absolute',
    backgroundColor: '#fff'
}

const boxContentStyle = {
    padding: '24px 24px 12px 24px'
}

export const CodeBoxContent = ({ children, title, style }) => {
    return (
        <>
            {/* CodeBoxMeta */}
            <div style={{ ...boxMetaStyle, ...style }}>
                {/* Title */}
                <div style={boxTitleStyle}>{title}</div>

                {/* Content */}
                <div style={boxContentStyle}>
                    {children}
                </div>

            </div>
        </>
    )
}

const CodeBox = ({ title, children }) => {
    return (
        <div style={boxStyle}>

            {/* Title */}
            <div style={{ padding: '16px 24px', borderBottom: '1px solid #f0f0f0', fontSize: '16px', fontWeight: '500', marginTop: '5px' }}>
                {title}
            </div>

            {children}
        </div>
    )
}

export default CodeBox
