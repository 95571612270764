import { Spin } from 'antd'
import React from 'react'


const LoadingPage = () => {
    return (
        <div
            style={{
                height: '100vh',
                width: '100wh',
                position: 'relative',
            }}
        >
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Spin size="large" />
            </div>
        </div>
    )
}

export default LoadingPage
