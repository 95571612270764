import { useState } from 'react'

function useHover() {
    const [hover, setHover] = useState(false)

    const mouseOver = () => {
        setHover(true)
    }

    const mouseOut = () => {
        setHover(false)
    }

    const hoverProps = {
        onMouseOver: mouseOver,
        onMouseOut: mouseOut,
    }

    return [hover, hoverProps]
}

export default useHover