import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
    Table,
    Tag,
    Button,
    Menu,
    Dropdown,
    Modal,
    Row,
    Col,
    Input,
    Select,
    Card,
    Space,
    message,
} from 'antd';

import {
    DownOutlined,
    ExclamationCircleOutlined,
    PlusCircleOutlined
} from "@ant-design/icons";

import qs from 'qs';

import useBreadcrumb from 'hooks/useBreadcrumb';
import useService from 'hooks/useService';

import { axiosAPI } from 'http';
import UserModalForm from './UserModalForm';

const TableRowActionMenu = function (props) {
    const items = useMemo(function () {
        const items = [];

        // if (props?.onClickApproval) {
        //     items.push({
        //         key: '1',
        //         label: 'ยืนยันการลงทะเบียน',
        //         onClick: props?.onClickApproval,
        //     });
        // }

        items.push({
            key: '2',
            label: 'แก้ไขผู้ใช้',
            onClick: props?.onClickEdit,
        });

        items.push({
            key: '3',
            label: 'ตั้งรหัสผ่านใหม่',
            onClick: props?.onClickReset,
        });

        // items.push({
        //     key: '3',
        //     label: 'ลบผู้ใช้',
        //     onClick: props?.onClickDelete,
        // });

        return items;
    }, [props?.reload]);

    return <Menu items={items} />
}

const InternalPage = function (props) {
    const state = useRef({});

    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({});

    const fetcher = useCallback(function (take = 10, page = 1, search = '', filter = {}) {
        setLoading(() => true);

        const params = {
            take,
            page,
            filter,
        };

        if (search = search.trim()) {
            params.search = search;
        }

        const paramsSerializer = function (params) {
            return qs.stringify(params)
        }

        axiosAPI.get('/api/v1/managers', { params, paramsSerializer })
            .then(function (resp) {
                setList(() => resp.data.data ?? []);
                setTotal(() => resp.data.total ?? 0);
            })
            .finally(function () {
                setLoading(() => false);
            });
    }, []);

    const handleClickDelete = useCallback(function (id) {
        Modal.confirm({
            title: 'กรุณายืนยันการดำเนินการ ?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            okButtonProps: {
                danger: true,
            },
            onOk: function () {
                setLoading(() => true);
                axiosAPI.delete(`/api/v1/managers/${id}`)
                    .finally(function () {
                        state.current.refresh();
                    });
            },
        });
    }, []);

    const handleClickApproval = useCallback(function (id) {
        setLoading(() => true);
        axiosAPI.patch(`/api/v1/users/${id}`, { type: 'approval', value: true })
            .finally(function () {
                state.current.refresh();
            });
    }, []);

    const handleClickReset = useCallback(function (id) {
        Modal.confirm({
            title: 'กรุณายืนยันการดำเนินการ ?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            okButtonProps: {
                danger: true,
            },
            onOk: function () {
                setLoading(() => true);
                axiosAPI.post(`/api/v1/managers/${id}/reset-password`)
                    .finally(function () {
                        state.current.refresh();
                        message.success('ดำเนินการสำเร็จ กรุณาตรวจสอบอีเมลของท่าน');
                    });
            },
        });
    }, []);

    const handleSearch = useCallback(function (text) {
        clearTimeout(state.current.searchDelay);
        state.current.searchDelay = setTimeout(() => {
            setPage(() => 1);
            setSearch(() => text);
        }, 600);
    }, []);

    const handleFilter = useCallback(function (name, value) {
        setPage(() => 1);
        setFilter((prev) => ({ ...prev, [name]: value.trim() || undefined }));
    }, []);

    const columns = useMemo(function () {
        return [
            {
                title: 'ชื่อ - สกุล',
                dataIndex: 'name',
            },
            {
                title: 'ระดับ',
                dataIndex: 'role',
                // width: '5%',
                render: (text) => text?.toUpperCase()
            },
            {
                title: 'สถานะ',
                // width: '5%',
                render: function (row) {
                    if (row?.id === 1) {
                        return (
                            <Tag color="green">
                                ใช้งาน
                            </Tag>
                        )
                    }

                    return (
                        <Tag color={row?.is_enabled === 1 ? 'green' : 'default'}>
                            {row?.is_enabled === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}
                        </Tag>
                    );
                },
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                width: '10%',
                render: function (_, row) {
                    return (
                        <Dropdown overlay={
                            <TableRowActionMenu
                                reload={props?.reload}
                                onClickReset={() => handleClickReset(row.id)}
                                onClickEdit={() => props?.onEdit(row)}
                                onClickDelete={() => handleClickDelete(row.id)}
                                onClickApproval={row.registered_at ? undefined : (() => handleClickApproval(row.id))}
                            />
                        }>
                            <Button ghost type="primary">
                                จัดการ <DownOutlined />
                            </Button>
                        </Dropdown>
                    )
                },
            },
        ];
    }, [list, total, props?.reload]);

    useEffect(function () {
        fetcher(take, page, search, filter);
        state.current.refresh = () => fetcher(take, page, search, filter);
    }, [take, page, search, filter, props?.reload]);

    return (
        <>
            <Row gutter={15}>
                <Col lg={6}>
                    <Input placeholder="ค้นหา" onChange={(e) => handleSearch(e.target.value)} allowClear />
                </Col>
                {/* <Col>
                    <Select defaultValue="" style={{ width: 120 }} onChange={(value) => handleFilter('is_registered', value)}>
                        <Select.Option value="">all</Select.Option>
                        <Select.Option value="1">registered</Select.Option>
                        <Select.Option value="0">not registered</Select.Option>
                    </Select>
                </Col> */}
            </Row>

            <div style={{ margin: '1rem 0' }}></div>

            <Table
                loading={loading}
                columns={columns}
                dataSource={list}
                rowKey={'id'}
                pagination={{
                    pageSize: 10,
                    current: page,
                    total: total,
                    onChange: (page) => setPage(page),
                    showSizeChanger: false
                }}
                scroll={{ x: true }}
                locale={{ emptyText: 'ไม่มีข้อมูล' }}
            />
        </>
    )
}

const UsersPage = () => {
    const { auth: { userInfo } } = useService()
    const [modal, setModal] = useState({
        visible: false,
        data: null
    });
    const [reload, setReload] = useState(null);

    const onModal = (visible, data = null) => {
        setModal({ visible, data })
    }

    const onReload = () => {
        setReload(Date.now())
    }

    useBreadcrumb([
        { page: 'ผู้ใช้งาน', link: '/users', icon: '' }
    ])

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col md={{ span: 24 }} lg={{ span: 24 }}>
                    <Card
                        title="รายการผู้ใช้งาน"
                        extra={(
                            <Space>
                                <Button onClick={() => onModal(true)} ghost type="primary" icon={<PlusCircleOutlined />}>เพิ่มผู้ใช้งาน</Button>
                            </Space>
                        )}
                    >
                        <InternalPage reload={reload} onEdit={(d) => onModal(true, d)} />
                        {/* <DepartmentsTable onEdit={handleEdit} onDelete={handleDelete} reload={state.reload} /> */}
                    </Card>
                </Col>
            </Row>

            {modal?.visible && (
                <UserModalForm
                    {...modal}
                    onCancel={() => onModal(false, null)}
                    reload={onReload}
                />
            )}
        </>
    )
}

// const UsersPage = () => {
//     const { auth: { userInfo } } = useService()

//     useBreadcrumb([
//         { page: 'ผู้ใช้งาน', link: '/users', icon: '' }
//     ])

//     return (
//         <UsersPagePrivate profile={userInfo} />
//     )
// }

export default UsersPage;