import React from "react";
import { Col, Modal, Row } from "antd";
import dayjs from "dayjs";
// import { getNameFromLogCodeName } from "utils/log";
// import ReactJson from "react-json-view";
import { DATE_TIME_FORMAT } from "utils/helpers";

const UserLogModalDetail = ({ visible, onCancel, data }) => {
  return (
    <Modal
      width={1000}
      visible={visible}
      title="ข้อมูล"
      cancelText="ปิด"
      onCancel={onCancel}
      okText={false}
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
    >
      <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          {/* <b>กิจกรรม</b> : {getNameFromLogCodeName(data?.name, data?.value)} */}
        </Col>
      </Row>

      <Row gutter={[16, 10]} style={{ marginBottom: "10px" }}>
        {data?.value?.length > 120 ? (
          <>
            <Col span={24}>
              <b>รายละเอียด</b>:
            </Col>
            <Col
              span={24}
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                overflowY: "scroll",
                paddingTop: 15,
                border: "1px solid #eee",
              }}
            >
              {/* <ReactJson src={data?.value} displayDataTypes={false} /> */}
            </Col>
          </>
        ) : (
          <Col span={24}>
            <b>รายละเอียด</b>:{" "}
            <p style={{ paddingTop: 15 }}>
              {/* <ReactJson src={data?.value} displayDataTypes={false} /> */}
            </p>
          </Col>
        )}
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          <b>วันที่ - เวลา</b> :{" "}
          {dayjs(data?.created_at).format(DATE_TIME_FORMAT)}
        </Col>
      </Row>
    </Modal>
  );
};

export default UserLogModalDetail;
