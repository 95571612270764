import React, { useCallback, useEffect } from 'react'
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import message from 'antd/lib/message';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';

// import { NORMAL_REGEX } from 'utils/helpers';
import useService from 'hooks/useService';
import { getMsg } from 'utils/codeMsg';

export function useCreateModal(onSubmit) {
    const { modal } = useService();
    const [form] = Form.useForm();

    return useCallback((data) => {
        modal.setModal({
            show: true,
            header: `เพิ่มแผนก`,
            content: <BaseForm form={form} data={data} />,
            size: 500,
            okText: "บันทึก",
            cancelText: "ยกเลิก",

            onOk: () => {
                form
                    .validateFields()
                    .then((values) => {
                        onSubmit(values)
                            .then(res => {
                                form.resetFields()
                                message.success(getMsg('CREATE_SUCCESS'))
                                modal.closeModal()
                            })
                            .catch(err => message.error(getMsg('CREATE_FAIL')))
                    })
                    .catch((info) => {
                    });
            },
            onCancel: () => {
                form.resetFields();
                modal.closeModal();
            },
        });
    }, [modal, onSubmit, form]);
}

export function useEditModal(onSubmit) {
    const { modal } = useService();
    const [form] = Form.useForm();

    return useCallback((data) => {
        modal.setModal({
            show: true,
            header: `แก้ไขแผนก`,
            content: <BaseForm form={form} data={data} />,
            size: 500,
            okText: "บันทึก",
            cancelText: "ยกเลิก",

            onOk: () => {
                form
                    .validateFields()
                    .then((values) => {
                        values.id = data?.id;

                        onSubmit(values)
                            .then(res => {
                                form.resetFields()
                                message.success(getMsg('UPDATE_SUCCESS'))
                                modal.closeModal()
                            })
                            .catch(err => message.error(getMsg('UPDATE_FAIL')))
                    })
                    .catch((info) => {
                    });
            },
            onCancel: () => {
                form.resetFields();
                modal.closeModal();
            },
        });
    }, [modal, onSubmit, form]);
}

export default function BaseForm({ data, setCancel, form }) {

    useEffect(() => {
        form.setFieldsValue({
            name: data?.name,
        })
        // eslint-disable-next-line
    }, [])

    return (
        <Form
            form={form}
            layout="vertical"
            name="control-hooks"
        // onValuesChange={() => setCancel(true)}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="locations"
                        label="สถานที่"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุสถานที่'
                            },
                        ]}
                        initialValue="1"
                    >
                        <Select
                            defaultValue=""
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="เลือกสถานที่"
                            style={{ width: '100%' }}
                        // onChange={(value) => state.handleSearch('filter[issue_type_ids]', value)}
                        >
                            <Select.Option value="">เลือกสถานที่</Select.Option>
                            <Select.Option value="1">ตึก A ฝั่งขวา</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item
                        name="floor"
                        label="ชั้น"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุชั้น'
                            },
                        ]}
                        initialValue="1"
                    >
                        <Select
                            defaultValue=""
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="เลือกชั้น"
                            style={{ width: '100%' }}
                        // onChange={(value) => state.handleSearch('filter[issue_type_ids]', value)}
                        >
                            <Select.Option value="">เลือกชั้น</Select.Option>
                            <Select.Option value="1">ชั้น 1</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="แผนก"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกแผนก'
                            },
                        ]}
                        initialValue={data?.name}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                </Col>
            </Row>
        </Form >
    )
}