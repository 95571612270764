import React from 'react'
import useBreadcrumb from 'hooks/useBreadcrumb';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import CardComplaint from './card.complaint';
import CardStatWeek from './card.stat.week';
import CardStatYear from './card.stat.year';
import CardStatRose from './card.stat.rose';

const IndexPage = () => {
    useBreadcrumb([]);

    return (
        <>
            <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                <Col md={{ span: 24 }} lg={{ span: 12 }} xxl={12}>
                    <CardComplaint />
                </Col>

                <Col md={{ span: 24 }} lg={{ span: 12 }} xxl={12}>
                    <CardStatWeek />
                </Col>

                {/* <Col md={{ span: 24 }} lg={{ span: 12 }} xxl={12}>
                </Col>

                <Col md={{ span: 24 }} lg={{ span: 12 }} xxl={12}>
                </Col> */}
            </Row>

            <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                <Col md={{ span: 24 }} lg={{ span: 12 }} xxl={12}>
                    <CardStatRose />
                </Col>

                <Col md={{ span: 24 }} lg={{ span: 12 }} xxl={12}>
                    <CardStatYear />
                </Col>
            </Row>
        </>
    )
}

export default IndexPage