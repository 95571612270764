import React, { useMemo } from 'react'
import { EnvironmentOutlined } from '@ant-design/icons';

function isThai(text) {
    const thaiRegex = /[\u0E00-\u0E7F]/;
    return thaiRegex.test(text);
}

const LocationLink = (props) => {
    const data = useMemo(() => props?.data, [props])
    const address = useMemo(() => {
        // const splits = data?.address?.toLowerCase()?.split(' ');
        let tambonMatch = data?.address.match(/(Tambon [^,]+)/);
        let amphoeMatch = data?.address.match(/(Amphoe [^,]+)/);
        let tambon = tambonMatch ? tambonMatch[0] : '';
        let amphoe = amphoeMatch ? amphoeMatch[0] : '';

        if (!tambon && !amphoe) {
            let tambonMatch = data?.address.match(/(ตำบล[^\s]+)/);
            let amphoeMatch = data?.address.match(/(อำเภอ[^\s]+)/);
            let tambon = tambonMatch ? tambonMatch[0] : '';
            let amphoe = amphoeMatch ? amphoeMatch[0] : '';

            return (tambon || amphoe) ? `${tambon} ${amphoe}`.replace(',', '').trim() : data?.address;
        }

        return (tambon || amphoe) ? `${tambon} ${amphoe}`.replace(',', '').trim() : data?.address;
    }, [data]);

    return (
        <a
            href={`https://www.google.com/maps/place/${data?.coordinate}`}
            style={{ whiteSpace: 'pre-wrap', pointerEvents: 'auto' }}
            target='_blank'
        >
            <EnvironmentOutlined /> {address ?? '-'}
        </a>
    )
}

export default LocationLink