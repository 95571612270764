import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';

const DatePicker = generatePicker(dayjsGenerateConfig);

export const locale = {
    "lang": {
        locale: 'th_TH',
        today: 'วันนี้',
        now: 'ตอนนี้',
        backToToday: 'กลับไปยังวันนี้',
        ok: 'ตกลง',
        clear: 'ลบล้าง',
        month: 'เดือน',
        year: 'ปี',
        timeSelect: 'เลือกเวลา',
        dateSelect: 'เลือกวัน',
        monthSelect: 'เลือกเดือน',
        yearSelect: 'เลือกปี',
        decadeSelect: 'เลือกทศวรรษ',
        yearFormat: 'BBBB',
        dateFormat: 'D/M/BBBB',
        dayFormat: 'D',
        dateTimeFormat: 'D/M/BBBB HH:mm:ss',
        monthFormat: 'MMM',
        monthBeforeYear: true,
        previousMonth: 'เดือนก่อนหน้า (PageUp)',
        nextMonth: 'เดือนถัดไป (PageDown)',
        previousYear: 'ปีก่อนหน้า (Control + left)',
        nextYear: 'ปีถัดไป (Control + right)',
        previousDecade: 'ทศวรรษก่อนหน้า',
        nextDecade: 'ทศวรรษถัดไป',
        previousCentury: 'ศตวรรษก่อนหน้า',
        nextCentury: 'ศตวรรษถัดไป',
        rangeYearFormat: ['BBBB', 'BBBB']
    },
    "dateFormat": "BBBB-MM-DD",
    "dateTimeFormat": "BBBB-MM-DD HH:mm:ss",
    "weekFormat": "BBBB-wo",
    "monthFormat": "BBBB-MM",
    "yearFormat": "BBBB",
}

export default DatePicker;