import { useState } from 'react';
import { Modal, Form, Input, Upload, message, Button, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import useConfirmCancel from 'hooks/useConfirmCancel'
import { uploadFile } from 'services/files'
import { FALLBACK_IMG, getBase64 } from 'utils/helpers'

import useService from 'hooks/useService';
import { getMsg } from 'utils/codeMsg';
import { updateProfile } from 'services/auth';
import { usePrompt } from 'hooks/usePrompt';
import { axiosAPI } from 'http';


export default function ProfileModalForm({ visible, onCancel, onUpdate, reload, data }) {
    const { auth: { userInfo: profile } } = useService()
    const [cancel, setCancel, confirmCancel] = useConfirmCancel(onCancel)
    const [count, setCount] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [checkPassword, setCheckPassword] = useState(false);
    const [imageUrl, setImageUrl] = useState(data?.signature);

    usePrompt('Changes you made may not be saved.', cancel);

    let options = {
        title: 'แก้ไขโปรไฟล์',
        require: false
    };

    const onSubmit = async (values) => {
        // console.log('Received values of form: ', values);
        delete values.confirm
        if (count > 0) {
            const formData = new FormData();
            formData.append('file', fileList[0]);
            const respFileName = await uploadFile(formData);
            values.signature = respFileName.data.filename;
            setFileList([]);
        }

        if (!values.password) {
            delete values.password;
        }

        axiosAPI.patch(`/api/v1/managers/${data?.id}`, values)
            .then(res => {
                message.success(getMsg('UPDATE_SUCCESS'));
                reload();
                onCancel();
            })
            .catch(error => {
                message.error(getMsg(error.response.data.code));
            })
        // updateProfile(values)
        //     .then(res => {
        //         message.success(getMsg('UPDATE_SUCCESS'))
        //         reload();
        //         onCancel();
        //     })
        //     .catch(error => {
        //         let errMsg = error?.response?.data?.code ?? 'UPDATE_FAIL';
        //         message.error(getMsg(errMsg))
        //     })

        // onUpdate(values);
    };

    const [form] = Form.useForm();

    const props = {
        onRemove: file => {
            setFileList(fileList => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                return newFileList
            })
            setCount(count - 1)
        },
        beforeUpload: file => {
            const maxSize = 1024 * 1024 * 1;
            const typeOfFiles = ['image/jpg', 'image/jpeg', 'image/png'];
            if (!typeOfFiles.includes(file.type)) {
                message.error(`${file.name} ${getMsg('UPLOAD_NOT_ALLOW')}`);
                return typeOfFiles.includes(file.type) ? true : Upload.LIST_IGNORE;
            }

            if (file.size > maxSize) {
                message.error(`${file.name} ${getMsg('UPLOAD_OVERSIZE')}`);
                return file.size <= maxSize ? true : Upload.LIST_IGNORE;
            }

            setFileList([file])
            setCount(count + 1)
            return false;
        },
    };

    const onChangeImage = (e) => {
        getBase64(e.file, imageUrl =>
            setImageUrl(imageUrl)
        );
    }

    return (
        <Modal
            width={500}
            visible={visible}
            title={options.title}
            okText="บันทึก"
            cancelText="ยกเลิก"
            // destroyOnClose={true}
            onCancel={confirmCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        // form.resetFields();
                        onSubmit(values);
                    })
                    .catch((info) => {
                        // console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="control-hooks"
                onValuesChange={() => setCancel(true)}
            >
                <Form.Item
                    name="name"
                    label="ชื่อ - สกุล"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณากรอกชื่อ - สกุล'
                        },
                    ]}
                    initialValue={data?.name}
                >
                    <Input autoComplete="off" />
                </Form.Item>
                {/* <Form.Item
                    name="password"
                    label="รหัสผ่านใหม่ (กรณีเปลี่ยนรหัสผ่าน)"
                    rules={[
                        {
                            required: options.require,
                            min: 8,
                            message: 'กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password onChange={e => e.target.value.length > 0 ? setCheckPassword(true) : setCheckPassword(false)} autoComplete="off" />
                </Form.Item> */}

                {/* {checkPassword && (
                    <>
                        <Form.Item
                            name="confirm"
                            label="ยืนยันรหัสผ่านใหม่"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณายืนยันรหัสผ่าน',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('ยืนยันรหัสผ่านไม่ถูกต้อง'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            name="current_password"
                            label="รหัสผ่านปัจจุบัน"
                            rules={[
                                {
                                    required: true,
                                    min: 8,
                                    message: 'กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัว',
                                },
                            ]}
                        >
                            <Input.Password autoComplete="off" />
                        </Form.Item>
                    </>
                )} */}

                {/* {profile?.level !== 9 && (
                    <>
                        <Image
                            src={imageUrl ?? '/public/images/no-image.png'}
                            fallback={FALLBACK_IMG}
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'contain'
                            }}
                        />

                        <Form.Item
                            name="signature"
                            label="รูปภาพลายเซ็น [ไม่เกิน 1 MB ชนิดไฟล์ jpg, jpeg, png]"
                            rules={[
                                {
                                    required: false,
                                    message: ''
                                },
                            ]}
                            valuePropName={fileList}
                        >
                            <div style={{ maxWidth: '250px' }}>
                                <Upload
                                    {...props}
                                    maxCount={1}
                                    defaultFileList={[...fileList]}
                                    onChange={onChangeImage}
                                >
                                    <Button icon={<UploadOutlined />}>เลือกรูปภาพ</Button>
                                </Upload>
                            </div>
                        </Form.Item>
                    </>

                )} */}
            </Form>
        </Modal>
    );
};