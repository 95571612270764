import React, { useEffect } from "react";
import { Button, Col, Dropdown, Input, Menu, Row, Space, Table, Tag } from "antd";
import { DownOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { observer, useLocalObservable } from "mobx-react-lite";
// import { axiosAPI } from '../../http';
// import { runInAction } from "mobx";
import LabelFilter from "components/LabelFilter";
import MySwitch from "components/MySwitch";

const DepartmentsTable = ({ onEdit, onDelete, reload }) => {
    let delayTimer;
    const state = useLocalObservable(() => ({
        loading: false,
        params: { page: 1, take: 10 },
        total: 0,
        per_page: 15,
        dataSource: [],
        fetchData() {
            this.dataSource = [
                { key: 1, name: 'สมชาย ขยันซ่อม', email: 'example@codesmash.net', phone: '0951234567', status: 0 },
                { key: 2, name: 'สมหญิง เก่งปัดกวาด', email: 'example@codesmash.net', phone: '0951234567', status: 1 },
            ];
            // Mock
            this.per_page = 10;
            this.total = 3;
            // this.setLoading(true)

            // const params = this.params

            // axiosAPI.get("/api/v1/issue-types", { params }).then(res => {
            //     runInAction(() => {
            //         this.dataSource = res.data.data.map(data => {
            //             return { key: data.id, ...data }
            //         });
            //         this.per_page = res.data.per_page;
            //         this.total = res.data.total;
            //         this.setLoading(false)
            //     })
            // });
        },
        changePage(page) {
            this.params.page = page;
            this.fetchData();
        },
        setLoading(loading) {
            this.loading = loading;
        },

        handleSearch(key, value) {
            clearTimeout(delayTimer);

            delayTimer = setTimeout(() => {
                if (!value) {
                    value = undefined;
                }
                this.params = { ...this.params, [key]: value, page: 1 };
                this.fetchData()
            }, 700);
        }
    }));

    useEffect(() => {
        state.fetchData();
        // eslint-disable-next-line
    }, [reload])

    const columns = [
        {
            title: "ชื่อ - สกุล",
            render: (row) => row?.name
        },
        {
            title: "อีเมล",
            render: (row) => row?.email
        },
        {
            title: "เบอร์โทรศัพท์",
            render: (row) => row?.phone
        },
        // {
        //     title: "สถานะ",
        //     render: (row) => row?.status
        // },
        {
            title: "สถานะการใช้งาน",
            width: "10%",
            align: 'center',
            render: (row) => {
                if (row?.status === 0) {
                    return <Tag color="purple">รออนุมัติ</Tag>;
                }

                return <MySwitch id={row.id} enabled={row?.status} toggleFunc={() => console.log('toggle')} />
            }
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (text, row) => (
                <Space size="middle">
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => onEdit(row)}>
                                    แก้ไข
                                </Menu.Item>
                                <Menu.Item key="delete" icon={<DeleteOutlined />} onClick={() => onDelete(row.id)}>
                                    ลบ
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}
                    >
                        <Button ghost type="primary">
                            จัดการ <DownOutlined />
                        </Button>
                    </Dropdown>
                </Space>
            ),
            width: "5%",
        },
    ];

    return (
        <>
            <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                <Col span={12} md={8} lg={6} xl={4}>
                    <LabelFilter label="ค้นหา" >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="ค้นหา"
                            allowClear
                            onChange={(e) => state.handleSearch('search', e.target.value)}
                        />
                    </LabelFilter>
                </Col>
            </Row>

            <Table
                // rowClassName="table-row-pointer"
                loading={state.loading}
                columns={columns}
                dataSource={state.dataSource}
                pagination={{
                    pageSize: state.per_page,
                    current: state.params.page,
                    total: state.total,
                    onChange: (page) => state.changePage(page),
                    showSizeChanger: false
                }}
                scroll={{ x: true }}
                locale={{ emptyText: "ไม่มีข้อมูล" }}
            />
        </>
    );
};

export default observer(DepartmentsTable);
