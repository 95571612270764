import { axiosAPI } from '../http'
const REFRESH_TOKEN = getToken()?.refresh_token;

export function authLogin(data) {
    return axiosAPI({
        method: 'post', //you can set what request you want to be
        url: `/api/v1/auth/signin`,
        data: data,
    })
}

export function authLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
}

export function authSignout() {
    return axiosAPI({
        method: 'delete',
        url: `/api/v1/auth/signout`,
        data: {
            "payload": `${REFRESH_TOKEN}`
        },
    })
}

export function getToken() {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
}

export function setToken(userToken) {
    localStorage.setItem('token', JSON.stringify(userToken));
}

export function authSession() {
    return axiosAPI.get(`/api/v1/auth`)
}

export function setProfile(userSession) {
    localStorage.setItem('profile', JSON.stringify(userSession));
}

export function getProfile() {
    const profileString = localStorage.getItem('profile');
    const userProfile = JSON.parse(profileString);
    return userProfile;
}

export function updateProfile(data) {
    return axiosAPI.patch(`/auth`, data);
}