import { useState } from 'react'
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const useConfirmCancel = (onCancel) => {
    const [cancel, setCancel] = useState(false);

    const confirmCancel = () => {
        if (cancel) {
            Modal.confirm({
                title: 'ต้องการปิดใช่หรือไม่ ?',
                icon: <ExclamationCircleOutlined />,
                content: '',
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
                onOk: () => onCancel()
            });
        } else {
            onCancel()
        }
    }

    return [cancel, setCancel, confirmCancel]
}

export default useConfirmCancel;