import React from 'react'
import { Breadcrumb } from 'antd';
import { Link } from "react-router-dom";
import useService from '../hooks/useService';
import { observer } from 'mobx-react-lite';

const BreadcrumbLayout = () => {
    const { app: { breadcrumbs } } = useService();

    return (
        <>
            {breadcrumbs.length > 0 && (
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    {breadcrumbs.length > 0 && (
                        <Breadcrumb.Item>
                            <Link to="/">หน้าหลัก</Link>
                        </Breadcrumb.Item>
                    )}

                    {breadcrumbs.length > 0 && breadcrumbs.map((item, idx) => (
                        <Breadcrumb.Item key={idx}>
                            {item.link ? (
                                <Link to={item.link}>{item.page}</Link>
                            ) : (
                                item.page
                            )}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
            )}

        </>
    )
}

export default observer(BreadcrumbLayout)
